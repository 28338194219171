import React from "react";
import { s3baseUrl } from "src/config/config";
import UserInfoCard from "./UserInfoCard";

export default function UsersList({
  feedActivity,
  totalPages,
  pageNumber,
  lastBookElementRef,
  loadMoreData,
  isLoadingMore,
}) {
  return (
    <div className="row feed-activity-box">
      {feedActivity.map((activity, index) => {
        return (
          <div className="col-12 mt-3" key={index}>
            <UserInfoCard
              profile_image={
                s3baseUrl + activity.user_info_action_by?.profile_image
              }
              user_name={activity.user_info_action_by?.name}
              avatar_char={activity.user_info_action_by?.name[0]}
              date={activity?.createdAt}
              activity_type={
                activity.activity_type == "feedlike" ||
                activity.activity_type == "commentlike"
                  ? "like"
                  : "gratitude"
              }
            />
          </div>
        );
      })}
      {totalPages > pageNumber ? (
        <div className="col-12 text-center">
          <span
            onClick={loadMoreData}
            className="load-more-text"
            ref={lastBookElementRef}
          >
            {isLoadingMore ? "Loading..." : "Load More"}
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
