import React, { useEffect, useRef, useState } from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { Card, CircularProgress, Fab, Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import { Participants } from "./components";
import { s3baseUrl } from "src/config/config";
import { formatTime } from "src/utils/constants";
import moment from "moment";
const momenttz = require("moment-timezone");

let micError =
  "Microphone access is denied. Please grant permission in your browser settings.";
let cameraError =
  "'Camera permission is denied. Please enable it in your browser settings.'";

const Background = ({ imageUrl }) => (
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: `url(${imageUrl})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    }}
  />
);

let is_mp4_supported = MediaRecorder.isTypeSupported("video/mp4");
let video_support = "webm";
if (is_mp4_supported) {
  video_support = "mp4";
}

const constraints = {
  video: {
    width: 1280,
    height: 720,
    aspectRatio: 16 / 9,
  },
  audio: true,
};

export default function LiveStream(props) {
  const { feedDetail, liveStream, isFullScreen, setIsFullScreen } = props;
  const myVideo = useRef();
  const streamRef = useRef();
  const rtmpUrlRef = useRef("");
  const { topic } = useParams();
  const navigate = useNavigate();
  const [isMuted, setIsMuted] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [openParticipants, setOpenParticipants] = useState(false);
  const [openEndStream, setOpenEndStream] = useState(false);
  const [isScreenShared, setIsScreenShared] = useState(false);
  const [myStream, setMyStream] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { socket, userInfo, setStreamLastChunks } = useContentSetting();
  const [participants, setParticipants] = useState([userInfo]);

  let total_time = -2;
  if (liveStream?.stream_start_time) {
    const startTime = moment(liveStream.stream_start_time);
    const endTime = momenttz.tz(new Date(), "Europe/Dublin");
    total_time = endTime.diff(startTime, "seconds");
  }
  const [timer, setTimer] = useState(total_time);

  const handleOpenParticipantsDrawer = () => {
    setOpenParticipants(true);
  };

  const handleCloseParticipantsDrawer = () => {
    setOpenParticipants(false);
  };

  const handleOpenSettingsDrawer = () => {
    setOpenSettings(true);
  };

  const handleCloseSettingsDrawer = () => {
    setOpenSettings(false);
  };

  const handleEndStreamConfirmation = () => {
    setOpenEndStream(true);
  };

  const handle_upload_chunk = async ({ chunks, is_last }) => {
    const mergedBlob = new Blob(chunks, {
      type: `video/${video_support}`,
    });
    const formData = new FormData();
    formData.append("file", mergedBlob, `chunk.${video_support}`);
    formData.append("rtmpUrl", rtmpUrlRef.current);

    console.log(...formData, "formDataformDataformData");
    try {
      const response = await fetch(
        "https://livestream.dynamitelifestyle.com/api/stream",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Chunk uploaded successfully:", result);
      } else {
        console.error("Failed to upload chunk:", response.statusText);
      }
    } catch (error) {
      console.error("Chunk upload error:", error);
    }
    setStreamLastChunks([]);
  };

  const handleStartRecording = (stream) => {
    if (stream) {
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: `video/${video_support}`,
      });
      mediaRecorder.ondataavailable = async (event) => {
        if (event.data.size > 0) {
          handle_upload_chunk({ chunks: [event.data] });
        }
      };
      mediaRecorder.start(5000);
      stream.getTracks().forEach((track) => {
        track.onended = () => mediaRecorder.stop();
      });
    } else {
      console.error("No stream available for recording.");
    }
  };

  const handleEndStream = () => {
    if (!streamRef.current) return;

    let last_chunks = [];
    setStreamLastChunks((old) => {
      last_chunks = old;
      return old;
    });
    streamRef.current.getTracks().forEach((track) => track.stop());
    if (myVideo.current) {
      myVideo.current.srcObject = null;
    }
    socket.emit("end_stream_event", { feed_id: topic });
  };

  const handleMuteToggle = () => {
    if (myStream) {
      const audioTracks = myStream.getAudioTracks();
      audioTracks.forEach((track) => (track.enabled = isMuted));
      let postData = {
        module_id: topic,
        is_video_muted: !isMuted,
      };
      socket.emit("is_video_muted", postData);
      setIsMuted(!isMuted);
    }
  };

  const handlePlayPauseToggle = async () => {
    if (myStream) {
      const videoTracks = myStream.getVideoTracks();
      videoTracks.forEach((track) => (track.enabled = isPaused));
      let postData = {
        module_id: topic,
        is_video_paused: !isPaused,
      };
      socket.emit("is_video_paused", postData);
      setIsPaused(!isPaused);
    }
  };

  const stopScreenShare = () => {
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      myStream.getTracks().forEach((track) => track.stop());
      const videoTrack = stream.getVideoTracks()[0];
      if (isPaused) {
        videoTrack.enabled = false;
      } else {
        videoTrack.enabled = true;
      }
      myStream.removeTrack(myStream.getVideoTracks()[0]);
      myStream.addTrack(videoTrack);
      handleStartRecording(stream);
      myVideo.current.srcObject = stream;
      setMyStream(stream);
      setIsScreenShared(false);
      let socketData = { module_id: topic, is_screen_shared: false };
      socket.emit("is_screen_shared", socketData);
    });
  };

  const handleScreenShare = async () => {
    try {
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true,
      });
      myStream.getTracks().forEach((track) => track.stop());
      const videoTrack = screenStream.getVideoTracks()[0];
      myStream.removeTrack(myStream.getVideoTracks()[0]);
      myStream.addTrack(videoTrack);

      const videoTracks = myStream.getVideoTracks();
      videoTracks.forEach((track) => (track.enabled = true));

      setMyStream(myStream);
      setIsScreenShared(true);
      handleStartRecording(screenStream);
      videoTrack.onended = stopScreenShare;
      let socketData = { module_id: topic, is_screen_shared: true };
      socket.emit("is_screen_shared", socketData);
    } catch (err) {
      console.error("Error in screen sharing:", err);
    }
  };

  const live_stream_started_receiver = (data) => {
    console.log(data, "live_stream_started_receiver");
    rtmpUrlRef.current = data.rtmpUrl;
  };

  const preventReload = (e) => {
    if (
      e.key === "F5" ||
      (e.ctrlKey && e.key === "r") ||
      (e.metaKey && e.key === "r")
    ) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = "";

    if (myStream) {
      handleEndStream();
    }
  };

  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("keydown", preventReload);
    window.addEventListener("unload", handleEndStream);
    return () => {
      window.removeEventListener("keydown", preventReload);
      window.removeEventListener("unload", handleEndStream);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      handleEndStream();
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (myStream) {
      streamRef.current = myStream;
    }
  }, [myStream]);

  useEffect(async () => {
    const mic = await navigator.permissions.query({ name: "microphone" });
    if (mic.state === "denied") {
      alert(micError);
      return;
    }

    const camera = await navigator.permissions.query({ name: "camera" });
    if (camera.state === "denied") {
      alert(cameraError);
      return;
    }

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        const videoTracks = stream.getVideoTracks();
        videoTracks.forEach((track) => (track.enabled = false));
        setMyStream(stream);
        const data = {
          user_id: userInfo._id,
          module_id: topic,
          stream_info: stream,
          module_name: "feed",
        };

        socket.emit("live_stream_started", data);
        myVideo.current.srcObject = stream;
        setIsLoading(false);
        setTimeout(() => {
          handleStartRecording(stream);
        }, 20000);
      })
      .catch((err) => {
        console.error("Failed to get user media:", err);
      });
  }, [socket]);

  useEffect(() => {
    if (myStream) {
      socket.on("manage_stream_participant_receiver", (data) => {
        const user = data.user;
        let find_user = participants.find((u) => u._id === user._id);
        user.join_time = new Date();
        if (data.module_id === topic) {
          if (data.action === "user_joined") {
            if (!find_user) {
              setParticipants((old) => [user, ...old]);
            }
          } else {
            setParticipants((old) =>
              old.filter((participant) => participant._id !== user._id)
            );
          }
        }
      });
    }

    socket.on("live_stream_started_receiver", live_stream_started_receiver);

    return () => {
      socket.off("manage_stream_participant_receiver");
      socket.off("live_stream_started_receiver");
    };
  }, [myStream, socket]);

  return (
    <Card className="live-stream-wrapper">
      {isLoading ? (
        <CircularProgress className="live-stream-loader" color="primary" />
      ) : (
        isPaused &&
        !isScreenShared && (
          <Background imageUrl={s3baseUrl + feedDetail.image.thumbnail_1} />
        )
      )}

      <div className="live-stream-header">
        <div className="stream-info">
          <Icon
            className="me-1"
            fontSize={16}
            style={{ color: "red" }}
            icon="carbon:dot-mark"
          />
          <span>Live</span>
        </div>
        {timer >= 0 && (
          <div className="call-timer-section">
            <div className="call-timer-box">{formatTime(timer)}</div>
          </div>
        )}
        <div
          className="stream-users-info"
          onClick={handleOpenParticipantsDrawer}
        >
          <Icon
            className="me-1"
            fontSize={16}
            icon="ic:baseline-remove-red-eye"
          />
          <span>{participants.length}</span>
        </div>
      </div>

      <div className="end-stream-btn" onClick={handleEndStreamConfirmation}>
        <Tooltip title="End Stream">
          <Fab
            style={{ backgroundColor: "red", color: "white" }}
            size="medium"
            variant="extended"
            aria-label="add"
          >
            <Icon
              className="stream-control-icon"
              icon="material-symbols:call-end"
            />
          </Fab>
        </Tooltip>
      </div>

      {/* <div
        className="settings-stream-btn"
        onClick={handleOpenSettingsDrawer}
      >
        <Fab style={{}} size="medium" variant="extended" aria-label="add">
          <Icon
            className="stream-control-icon"
            icon="icon-park-solid:setting-two"
          />
        </Fab>
      </div> */}
      <video ref={myVideo} muted autoPlay playsinline className="w-100" />
      <div className="live-stream-footer">
        <div className="stream-controls-wrapper">
          <Tooltip title={isPaused ? "Play" : "Pause"}>
            <div className="stream-control">
              <Fab
                onClick={handlePlayPauseToggle}
                className="stream-control-button"
                size="medium"
                variant="extended"
                aria-label="add"
              >
                <Icon
                  className="stream-control-icon"
                  icon={`iconoir:video-camera${isPaused ? "-off" : ""}`}
                />
              </Fab>
            </div>
          </Tooltip>
          <Tooltip title={isMuted ? "Unmute" : "Mute"}>
            <div className="stream-control">
              <Fab
                onClick={handleMuteToggle}
                className="stream-control-button"
                size="medium"
                variant="extended"
                aria-label="add"
              >
                <Icon
                  className="stream-control-icon"
                  icon={`${
                    isMuted ? "material-symbols:mic-off" : "ic:outline-mic"
                  }`}
                />
              </Fab>
            </div>
          </Tooltip>
          {/* <Tooltip
            title={isScreenShared ? "Stop Screen Sharing" : "Share Screen"}
          >
            <Fab
              onClick={isScreenShared ? stopScreenShare : handleScreenShare}
              className="stream-control-button"
              size="medium"
              variant="extended"
              aria-label="screen-share"
            >
              <Icon
                className="stream-control-icon"
                icon={`fluent:share-screen-${
                  isScreenShared ? "stop" : "start"
                }-28-regular`}
              />
            </Fab>
          </Tooltip> */}
          <Tooltip title={isFullScreen ? "Exit Full Screen" : "Full Screen"}>
            <div className="stream-control">
              <Fab
                onClick={() => setIsFullScreen(!isFullScreen)}
                className="stream-control-button"
                size="medium"
                variant="extended"
                aria-label="add"
              >
                <Icon
                  className="stream-control-icon"
                  icon={`${
                    isFullScreen
                      ? "ic:baseline-fullscreen-exit"
                      : "ic:baseline-fullscreen"
                  }`}
                />
              </Fab>
            </div>
          </Tooltip>
        </div>
      </div>
      <CustomConfirmation
        open={openEndStream}
        setOpen={setOpenEndStream}
        title={"Are you sure you want to end this live stream?"}
        handleAgree={() => navigate("/thesource")}
      />
      <CustomDrawer
        isOpenDrawer={openParticipants}
        onOpenDrawer={handleOpenParticipantsDrawer}
        onCloseDrawer={handleCloseParticipantsDrawer}
        pageTitle="Live Stream Participants"
        componentToPassDown={
          <Participants
            module_id={topic}
            openParticipants={openParticipants}
            participants={participants}
            setParticipants={setParticipants}
          />
        }
      />

      {/* <CustomDrawer
        isOpenDrawer={openSettings}
        onOpenDrawer={handleOpenSettingsDrawer}
        onCloseDrawer={handleCloseSettingsDrawer}
        pageTitle="Live Streams Settings"
        componentToPassDown={
          <LiveStreamSettings zmClient={zmClient} mediaStream={mediaStream} />
        }
      /> */}
    </Card>
  );
}
