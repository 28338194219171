import { useEffect, useRef, useState } from "react";
import MissionFeedDetailNew from "./MissionFeedDetailNew";
import LeaderBoard from "../MissionConTrollNew/LeaderBoard";
import ApexChartDonat from "./ApexChart";
import { fShortenNumber, get_kmb_number } from "src/utils/formatNumber";
import { CircularProgress, Tooltip } from "@mui/material";
import AnalyticsChartHealth from "./AnalyticsChartHealth";
import AnalyticsChartrecreational from "./AnalyticsChartrecreational";
import AnalyticsChart from "./AnalyticsChart";
import ActionBarComponentProps from "../DynamiteDiary/calender";
import Missioncalender from "./Missioncalender";
import { cointlogo } from "src/assets";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import LeaderBoardCoins from "../MissionConTrollNew/LeaderBoardCoins";
import { get_short_string } from "src/utils/constants";
import CommunityStoriesTab from "src/components/MissionLevels/CommunityStoriesTab";
import ScheduleDetailActions from "./ScheduleDetailActions";
import SchadulesListDashBoard from "src/components/MissionLevels/SchadulesListDashBoard";
import { s3baseUrl } from "src/config/config";
import PieMissionChart from "./PieMissionChart";
import MissionsJourney from "../MissionConTrollNew/MissionsJourney";
import ReactApexChartDashboard from "./ReactApexChartDashboard";
import GroupedChart from "./GroupedChart";

const MainDashBoard = ({
  missionDashboaerd,
  missionDetail,
  missionLevel,
  loadMorePath,
  setLoadMorePath,
  streakLeaderBoard,
  setStreakLeaderBoard,
  coinLeaderBoard,
  setCoinLeaderBoard,
  loadMorePathCoin,
  setLoadMorePathCoin,
  missonSchedulesList,
  graphData,
  missionCommunity,
  missionSchedules,
  mcqData,
  graphStructure,
}) => {
  const [actionData, setActionData] = useState(
    missionDashboaerd?.current_mission_schedule
  );
  let mssionJourneyData = missonSchedulesList?.filter(
    (item) => item.content_settings?.show_in_mission_journey
  );

  const [leaderboardDatas, setLeaderboardData] = useState(
    missionDashboaerd.streak_leader_board_stats
  );
  return (
    <>
      {/* {missionCommunity.length > 0 && (
        <div className="mt-3">
          <CommunityStoriesTab missionCommunity={missionCommunity} />
        </div>
      )} */}
      <div className="mt-3 card pt-3 pb-3">
        <h3
          className="unlock-color mb-3 ms-3 challenge-heading"
          style={{ fontWeight: 500 }}
        >
          {missionDashboaerd?.mission?.content_settings?.schedule_heading}
        </h3>
        <SchadulesListDashBoard
          missionCommunity={missonSchedulesList}
          currentMissionSchedules={missionSchedules}
        />
      </div>
      <div className="row">
        <div className="col-lg-8 pe-0 h-100">
          <div className="row">
            <div className="col-lg-6 mt-1 pe-0">
              <div className="card d-flex streak-container h-100">
                <div className="d-flex justify-content-around">
                  <div>
                    {/* <ApexChartDonat /> */}
                    <div
                      style={{ position: "relative", display: "inline-flex" }}
                    >
                      {/* Background (Gray) CircularProgress */}
                      <CircularProgress
                        variant="determinate"
                        value={100}
                        size={80}
                        style={{
                          color: "#ffd88950",
                        }}
                      />
                      {/* Foreground (Blue) CircularProgress */}
                      <CircularProgress
                        variant="determinate"
                        value={
                          (missionDashboaerd.started_days * 100) /
                          missionDashboaerd.mission_duration
                        }
                        size={80}
                        style={{
                          color: "#ffd889",
                          position: "absolute",
                          left: 0,
                        }}
                      />
                      <div className="dashboard-progress-text">
                        <div className="dashboard-progress-text-title">
                          {`${(
                            (missionDashboaerd.started_days * 100) /
                            missionDashboaerd.mission_duration
                          ).toFixed(0)}`}
                          %
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <p className=" mb-0">
                      <div className="d-flex">
                        <span style={{ fontSize: "22px" }}>🔥</span>
                        <div style={{ marginLeft: "8px" }}>
                          <h4 style={{ marginBottom: "0px", fontSize: "24px" }}>
                            {missionDashboaerd.started_days} Days
                          </h4>
                          <p
                            style={{
                              fontSize: "13px",
                              color: "#ffd889",
                              marginTop: "3px",
                            }}
                          >
                            Completed Days
                          </p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <span style={{ fontSize: "24px" }}>🏆</span>
                        <div style={{ marginLeft: "8px" }}>
                          <h4 style={{ marginBottom: "0px", fontSize: "22px" }}>
                            {missionDashboaerd.remaining_days}{" "}
                            {missionDashboaerd.remaining_days > 1
                              ? "Days"
                              : "Day"}
                          </h4>
                          <p
                            style={{
                              fontSize: "13px",
                              color: "#ffd889",
                              marginBottom: "0px",
                              marginTop: "3px",
                            }}
                          >
                            Remaining Days
                          </p>
                        </div>
                      </div>
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-1 ps-2">
              {" "}
              <div
                className="mission-control-card p-3 w-100 h-100 pt-4"
                style={{ borderRadius: "4px" }}
              >
                <span>
                  <img src={cointlogo} style={{ width: "22%" }} />
                </span>
                <div className="d-flex justify-content-center total-coins">
                  <h4 style={{ marginBottom: "0px", fontSize: "24px" }}>
                    {fShortenNumber(missionDashboaerd.attracted_coins)}
                  </h4>
                  {/* <h3></h3> */}
                </div>
                {missionDashboaerd.target_coins -
                  missionDashboaerd.attracted_coins >
                  0 && (
                  <div className="more-coins mt-2">
                    Receive{" "}
                    <span>
                      {fShortenNumber(
                        missionDashboaerd.target_coins -
                          missionDashboaerd.attracted_coins
                      )}
                    </span>{" "}
                    more Coins as a reward for completing this mission
                  </div>
                )}
                <div className="coins-slider mt-3  mb-2 getCoins">
                  <div className="coins-slider-outer">
                    <Tooltip
                      title={fShortenNumber(missionDashboaerd.attracted_coins)}
                    >
                      <div
                        className="coins-slider-inner"
                        style={{
                          width:
                            (missionDashboaerd.attracted_coins * 100) /
                              missionDashboaerd.target_coins +
                            "%",
                          maxWidth: "100%",
                          cursor: "pointer",
                        }}
                      ></div>
                    </Tooltip>

                    <div style={{ marginTop: "4px" }}>
                      {" "}
                      {fShortenNumber(missionDashboaerd.target_coins)}
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>

            {missionSchedules &&
              missionSchedules.allowed_actions.length > 0 && (
                <div className="col-lg-12 mt-1">
                  <div className="card p-1">
                    <ScheduleDetailActions
                      missionSchedules={missionSchedules}
                      hide_Top_section={true}
                    />
                  </div>
                </div>
              )}
            {graphStructure.length > 0 && (
              <div className="col-lg-12 mt-2">
                <div className="card ps-2 pt-3">
                  <span className="challenge-heading unlock-color">
                    Questions Report
                  </span>
                  {/* <ReactApexChartDashboard graphData={graphData} /> */}
                  <GroupedChart graphStructure={graphStructure} />
                </div>
              </div>
            )}
            {/* {graphData.length > 0 &&
              graphData.map((item, index) => {
                return (
                  <>
                    <div
                      className={`${
                        index === graphData.length - 1 &&
                        graphData.length % 2 !== 0
                          ? "col-lg-12"
                          : graphData.length > 1
                          ? "col-lg-6"
                          : "col-lg-12"
                      } mt-2 h-100 ${
                        index === graphData.length - 1 &&
                        graphData.length % 2 !== 0
                          ? "pe-2"
                          : index % 2 === 0
                          ? "pe-0"
                          : "pe-2"
                      }`}
                    >
                      <div className="card h-100 pt-3">
                        <div className="ps-2">
                          <Tooltip title={item.question_statement}>
                            <div className="d-flex align-items-baseline">
                              <span
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  color: "#ffd889",
                                }}
                              >
                                Q .
                              </span>
                              <h4
                                class="ps-1"
                                style={{ fontSize: "14px", fontWeight: "200" }}
                              >
                                {get_short_string(item.question_statement, 30)}
                              </h4>
                            </div>
                          </Tooltip>
                        </div>
                        <AnalyticsChart item={item} />
                      </div>
                    </div>
                  </>
                );
              })} */}
            {mcqData.length > 0 &&
              mcqData.map((item, index) => {
                const isLastItem = index === mcqData.length - 1;
                const isOddNumber = mcqData.length % 2 !== 0;
                return (
                  <div
                    key={index}
                    className={`${
                      isLastItem && isOddNumber
                        ? "col-lg-12"
                        : mcqData.length > 1
                        ? "col-lg-6"
                        : "col-lg-12"
                    } mt-2 ${index % 2 === 0 && !isLastItem ? "pe-0" : "pe-2"}`}
                  >
                    <div className="card d-flex flex-column h-100">
                      <div className="ps-2 pt-3">
                        <Tooltip title={item.question_statement}>
                          <div className="d-flex align-items-baseline">
                            <span
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#ffd889",
                              }}
                            >
                              Q .
                            </span>
                            <h4
                              className="ps-1"
                              style={{ fontSize: "14px", fontWeight: "200" }}
                            >
                              {get_short_string(item.question_statement, 30)}
                            </h4>
                          </div>
                        </Tooltip>
                      </div>
                      <div className="flex-grow-1 d-flex align-items-center justify-content-center">
                        <PieMissionChart item={item} />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="col-lg-4 hide-in-mobile mt-1">
          {missionDetail?.mission_road_map_level?.badges?.length > 0 && (
            <div className="card mb-1">
              <h3
                className="unlock-color mt-3 ms-2 mb-1 challenge-heading"
                style={{ fontWeight: 500, fontSize: "16px" }}
              >
                Mission Rewards
              </h3>
              <p className="ms-2 mb-2 me-2" style={{ fontSize: "12px" }}>
                {missionLevel.achieveable_badge_text}
              </p>

              <div className="challenge-video-card mb-2">
                <div className="d-flex align-items-center level-icons mb-2  ms-2 overflow-x-auto">
                  {missionDetail.mission_road_map_level.badges.map(
                    (item, index) => {
                      return (
                        <>
                          <Tooltip
                            title={item.title + " " + item.no_of_badges + "x"}
                          >
                            <img
                              src={s3baseUrl + item.icon.thumbnail_1}
                              alt=""
                              style={{ width: "35px", height: "35px" }}
                            />
                          </Tooltip>
                        </>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          )}

          {mssionJourneyData.length > 0 && (
            <MissionsJourney
              title={"Mission Journey"}
              data={mssionJourneyData}
            />
          )}
          <LeaderBoard
            title={"Mission Leader Board"}
            data={leaderboardDatas}
            streakLeaderBoard={streakLeaderBoard}
            setStreakLeaderBoard={setStreakLeaderBoard}
            loadMorePath={loadMorePath}
            setLoadMorePath={setLoadMorePath}
            type="mission"
          />
          <div className="mt-2">
            <LeaderBoardCoins
              title={"Coins Leader Board"}
              data={leaderboardDatas}
              streakLeaderBoard={coinLeaderBoard}
              setStreakLeaderBoard={setCoinLeaderBoard}
              loadMorePath={loadMorePathCoin}
              setLoadMorePath={setLoadMorePathCoin}
              type="mission"
            />
          </div>
        </div>
      </div>{" "}
      <div className="row">
        <div className="col-lg-8 pe-0">
          <div className="row"></div>
        </div>
        <div className="col-lg-4 mt-2"></div>
      </div>
    </>
  );
};

export default MainDashBoard;
