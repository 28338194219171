import React, { useEffect, useRef, useState } from "react";

const VideoEncoderComponent = () => {
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const videoEncoderRef = useRef(null);
  const [encodedChunks, setEncodedChunks] = useState([]);

  useEffect(() => {
    const initStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        console.error("Error accessing webcam: ", err);
      }
    };

    initStream();
  }, []);

  const handleDownload = (blob) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `encoded-recording-${Date.now()}.webm`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleEncodedChunk = (chunk) => {
    setEncodedChunks((prevChunks) => [...prevChunks, chunk]);
  };

  const startRecording = () => {
    const stream = videoRef.current.srcObject;
    mediaRecorderRef.current = new MediaRecorder(stream, {
      mimeType: "video/webm; codecs=vp8",
    });

    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        encodeChunk(event.data);
      }
    };

    mediaRecorderRef.current.start(5000); // Record chunks every 5 seconds
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
  };

  const encodeChunk = (chunk) => {
    if (!videoEncoderRef.current) {
      const encoder = new VideoEncoder({
        output: handleEncodedChunk,
        error: (error) => console.error("Encoding error:", error),
      });

      encoder.configure({
        codec: "vp8", // Use 'h264' if preferred
        width: 640,
        height: 480,
        framerate: 30,
        bitrate: 500_000, // 500 kbps
      });

      videoEncoderRef.current = encoder;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const arrayBuffer = reader.result;
      const videoFrame = new VideoFrame(new Uint8Array(arrayBuffer), {
        timestamp: performance.now(),
      });

      videoEncoderRef.current.encode(videoFrame);
      videoFrame.close();
    };
    reader.readAsArrayBuffer(chunk);
  };

  const downloadEncodedFile = () => {
    if (encodedChunks.length > 0) {
      const concatenatedChunks = new Blob(encodedChunks, {
        type: "video/webm",
      });
      handleDownload(concatenatedChunks);
    }
  };

  return (
    <div>
      <video ref={videoRef} autoPlay playsInline></video>
      <button onClick={startRecording}>Start Recording & Encoding</button>
      <button onClick={stopRecording}>Stop Recording</button>
      <button onClick={downloadEncodedFile}>Download Encoded Video</button>
    </div>
  );
};

export default VideoEncoderComponent;
