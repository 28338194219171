import { Chip, Tooltip } from "@mui/material";
import React from "react";
import { communityHandScreenshot, communityHeart } from "src/assets";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

export default function HandleAction({
  isLiked,
  handleFeedAction,
  feeds_type,
  setShowAddComment,
  isGratituded,
  feed_id,
}) {
  return (
    <div className="row comment-icon justify-content-between">
      <div className="col-4 svg-color comment-icons text-center">
        {isLiked ? (
          <Chip
            label={
              <span className="d-flex">
                <img src={communityHeart} alt="" />
                &nbsp; Liked
              </span>
            }
            variant="outlined"
            onClick={() => {
              handleFeedAction(feed_id, "feedunlike");
            }}
          />
        ) : (
          <Chip
            label={
              <span className="d-flex">
                <FavoriteBorderIcon /> &nbsp;Like
              </span>
            }
            variant="outlined"
            onClick={() => {
              handleFeedAction(feed_id, "feedlike");
            }}
          />
        )}
      </div>
      {feeds_type === "event" && <div className="col-4"></div>}
      <div className="col-4 svg-color comment-icons text-center">
        <Chip
          onClick={() => {
            setShowAddComment(true);
          }}
          label={
            <>
              <ChatBubbleOutlineIcon />
              &nbsp; Comment
            </>
          }
          variant="outlined"
        />
      </div>
      {feeds_type === "general" && (
        <div className="col-4 svg-color comment-icons text-center">
          {isGratituded ? (
            <Tooltip title="Already Given Gratitude">
              <Chip
                label={
                  <span className="d-flex">
                    <img src={communityHandScreenshot} alt="" />
                    &nbsp; Already Given
                  </span>
                }
                color="primary"
                className="float-end me-1"
                variant="outlined"
              />
            </Tooltip>
          ) : (
            <Chip
              onClick={() => {
                handleFeedAction(feed_id, "gratitude");
              }}
              label={
                <span className="d-flex">
                  <img src={communityHandScreenshot} alt="" />
                  &nbsp; Gratitude
                </span>
              }
              variant="outlined"
            />
          )}
        </div>
      )}
    </div>
  );
}
