import React, { useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

const RLIveStream = () => {
  const videoRef = useRef(null); // Create a reference for the video element

  const socket = io("http://161.35.35.172:3002/"); // Initialize socket

  useEffect(() => {
    const startStreaming = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }

        const mediaRecorder = new MediaRecorder(stream, {
          mimeType: "video/webm; codecs=vp8",
          audioBitsPerSecond: 128000,
          videoBitsPerSecond: 2500000,
          framerate: 25,
        });
        // const mediaRecorder = new MediaRecorder(media, {
        //   audioBitsPerSecond: 128000,
        //   videoBitsPerSecond: 2500000,
        //   framerate: 25,
        // });
        mediaRecorder.start(25);
        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            console.log(event.data, "event.dataevent.data");
            socket.emit("binarystream", event.data);
          }
        };
      } catch (err) {
        console.error("Error accessing media devices.", err);
      }
    };

    startStreaming(); // Start streaming when the component mounts

    return () => {
      // Cleanup function, if needed
    };
  }, [socket]);

  return (
    <div>
      <video
        ref={videoRef}
        autoPlay
        muted
        playsInline
        style={{ width: "100%", height: "auto" }}
      />
    </div>
  );
};

export default RLIveStream;
