import { Fab, Tooltip } from "@mui/material";
import React from "react";
import { Icon } from "@iconify/react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

export default function LiveStreamFooter(props) {
  const {
    meetingSession,
    feedDetail,
    isPaused,
    isMuted,
    attendee_id,
    handleOpenSettingsDrawer,
    isSelected,
  } = props;
  const { socket, userInfo } = useContentSetting();

  const handlePlayPauseToggle = () => {
    if (isPaused) {
      meetingSession.audioVideo.startLocalVideoTile();
    } else {
      meetingSession.audioVideo.stopLocalVideoTile();
    }
    let postData = {
      module_id: feedDetail._id,
      is_video_paused: !isPaused,
      user_id: userInfo._id,
      attendee_id: attendee_id,
    };
    socket.emit("meeting_video_paused", postData);
    // setIsPaused((prev) => !prev);
  };

  const handleMuteToggle = () => {
    if (isMuted) {
      meetingSession.audioVideo.realtimeUnmuteLocalAudio();
    } else {
      meetingSession.audioVideo.realtimeMuteLocalAudio();
    }
    let postData = {
      module_id: feedDetail._id,
      is_video_muted: !isMuted,
      user_id: userInfo._id,
      attendee_id: attendee_id,
    };
    socket.emit("meeting_video_muted", postData);
    // setIsMuted((prev) => !prev);
  };

  return (
    <>
      <Tooltip title={isPaused ? "Play" : "Pause"}>
        <div className="stream-control">
          <Fab
            onClick={handlePlayPauseToggle}
            className="stream-control-button"
            size="medium"
            variant="extended"
            aria-label="add"
          >
            <Icon
              className="stream-control-icon"
              icon={`iconoir:video-camera${isPaused ? "-off" : ""}`}
            />
          </Fab>
        </div>
      </Tooltip>
      <Tooltip title={isMuted ? "Unmute" : "Mute"}>
        <div className="stream-control">
          <Fab
            onClick={handleMuteToggle}
            className="stream-control-button"
            size="medium"
            variant="extended"
            aria-label="add"
          >
            <Icon
              className="stream-control-icon"
              icon={`${
                isMuted ? "material-symbols:mic-off" : "ic:outline-mic"
              }`}
            />
          </Fab>
        </div>
      </Tooltip>
      {!isSelected && (
        <div className="settings-stream-btn" onClick={handleOpenSettingsDrawer}>
          <Fab style={{}} size="medium" variant="extended" aria-label="add">
            <Icon
              className="stream-control-icon"
              icon="icon-park-solid:setting-two"
            />
          </Fab>
        </div>
      )}
    </>
  );
}
