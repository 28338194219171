import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import { s3baseUrl } from "src/config/config";
import { mission_levels_list_api } from "src/DAL/MissionLevels/MissionLevels";
import { htmlDecode } from "src/utils/convertHtml";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MissionLevels() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [missionLevels, setMissionLevels] = useState([]);
  const [paddingMap, setPaddingMap] = useState({});

  const get_mission_levels = async () => {
    const result = await mission_levels_list_api();
    if (result.code === 200) {
      setMissionLevels(result.mission_levels);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDetail = (object) => {
    if (
      object.user_mission_status == "in_progress" ||
      object.user_mission_status == "completed"
    ) {
      navigate(`/mission-levels/level-details/${object._id}`);
    } else {
      navigate(`/mission-levels/level-detail/${object._id}`);
    }
  };

  const replacedString = (string, unlock_coins) => {
    if (!string) return;
    return string.replace(/{unlock_coins}/g, unlock_coins);
  };

  useEffect(() => {
    get_mission_levels();
  }, []);

  useEffect(() => {
    const calculatePaddings = () => {
      let newPaddingMap = {};
      missionLevels.forEach((level) => {
        level.missions.forEach((mission) => {
          let note_id = `note_${mission._id}`;
          setTimeout(() => {
            let element = document.querySelector(`#${note_id}`);
            if (element) {
              newPaddingMap[note_id] = element.offsetHeight;
              setPaddingMap((prevMap) => ({ ...prevMap, ...newPaddingMap }));
            }
          }, 500);
        });
      });
    };
    if (!isLoading) {
      calculatePaddings();
    }
  }, [missionLevels, isLoading]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container mission-levels mt-3">
      <div className="mission-level-heading">
        <PageDescription
          parameter="mission_levels_description"
          else_title="Mission Levels"
        />
      </div>
      <div className="row">
        {missionLevels.map((level) => (
          <div className="col-12 mb-3" key={level._id}>
            <div className="challenge-video-card">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex align-items-center level-icons mb-3">
                    <h2>{level.title}</h2>
                    {level.lavel_info?.badges?.map((badge) => (
                      <img
                        src={s3baseUrl + badge.icon.thumbnail_2}
                        alt=""
                        key={badge.icon.thumbnail_2}
                      />
                    ))}
                  </div>
                </div>
                {level.missions.map((mission) => {
                  let note_id = `note_${mission._id}`;
                  return (
                    <div
                      className="col-12 col-md-6 col-lg-4 mb-3"
                      key={mission._id}
                    >
                      <div
                        className="card mission-level-card"
                        style={{
                          paddingBottom: paddingMap[note_id] || 0,
                        }}
                        onClick={() => handleDetail(mission)}
                      >
                        <div className="mission-days">
                          {mission.mission_duration} Days Mission
                        </div>
                        <img
                          alt={mission.title}
                          src={s3baseUrl + mission.image.thumbnail_2}
                        />
                        <div className="p-3">
                          <h3>{htmlDecode(mission.title)}</h3>
                          {mission.short_description && (
                            <p className="mui-custom-card-description">
                              {htmlDecode(mission.short_description)}
                            </p>
                          )}
                          {mission.user_mission_status === "not_started" &&
                            mission.content_settings?.lock_description && (
                              <p className="start-mission-note" id={note_id}>
                                <span className="note-label">Note :</span>{" "}
                                {htmlDecode(
                                  replacedString(
                                    mission.content_settings?.lock_description,
                                    mission.coins_to_unlock
                                  )
                                )}
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
