import { useState } from "react";

import LeaderBoard from "../MissionConTrollNew/LeaderBoard";

import LeaderBoardCoins from "../MissionConTrollNew/LeaderBoardCoins";

import CommunityStoriesTab from "src/components/MissionLevels/CommunityStoriesTab";
const MobileLeaderBoaerd = ({
  missionDashboaerd,
  loadMorePath,
  setLoadMorePath,
  streakLeaderBoard,
  setStreakLeaderBoard,
  coinLeaderBoard,
  setCoinLeaderBoard,
  loadMorePathCoin,
  setLoadMorePathCoin,
  graphData,
  missionCommunity,
}) => {
  const [leaderboardDatas, setLeaderboardData] = useState(
    missionDashboaerd.streak_leader_board_stats
  );

  return (
    <>
      <div className="row">
        <div className="col-lg-4 mt-3">
          <LeaderBoard
            title={"Mission Leader Board"}
            data={leaderboardDatas}
            streakLeaderBoard={streakLeaderBoard}
            setStreakLeaderBoard={setStreakLeaderBoard}
            loadMorePath={loadMorePath}
            setLoadMorePath={setLoadMorePath}
            type="mission"
          />
          <div className="mt-2">
            <LeaderBoardCoins
              title={"Coins Leader Board"}
              data={leaderboardDatas}
              streakLeaderBoard={coinLeaderBoard}
              setStreakLeaderBoard={setCoinLeaderBoard}
              loadMorePath={loadMorePathCoin}
              setLoadMorePath={setLoadMorePathCoin}
              type="mission"
            />
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default MobileLeaderBoaerd;
