import React from "react";
import { s3baseUrl } from "src/config/config";
import MissionVideoPlayer from "../ReactPlayers/MissionVideoPlayer";
import { dd_date_format, dd_date_format_UPDATE } from "src/utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { videoShowLock } from "src/assets";
import { Tooltip } from "@mui/material";
export default function DayVideoTab({
  openQuestionsPopup,
  setOpenModal,
  schedule,
  handleChangeAction,
  isSubmitting,
  handleSubmit,
  hide_Top_section,
  handleUnlock,
  missionContentSettings,
  hideSubmitButton,
}) {
  const navigate = useNavigate();
  const { mission__id } = useParams();
  const replacedString = (string) => {
    if (!string) return;
    return string.replace(
      /{completed_date}/g,
      dd_date_format(schedule.completed_at)
    );
  };
  const replacedStringNote = (string) => {
    if (!string) return;
    return string
      .replace(/{started_at}/g, dd_date_format(schedule.started_at))
      .replace(/{end_date}/g, dd_date_format(schedule.end_date));
  };
  let find = schedule.allowed_actions.find(
    (item) => item.is_required === true && !item.is_checked
  );
  const today = moment();
  const startedAt = moment(schedule.started_at);

  return (
    <div className={`row ${!hide_Top_section ? "mt-3" : ""}`}>
      {!hide_Top_section && (
        <>
          <div
            className={`col-12 ${
              schedule.detailed_description ? "col-lg-8" : ""
            } `}
          >
            {schedule.video_url ? (
              <MissionVideoPlayer
                url={schedule.video_url}
                // isDisabled={Boolean(schedule.schedule_status === "locked")}
              />
            ) : (
              <img
                src={s3baseUrl + schedule.image.thumbnail_1}
                className="m-auto"
              />
            )}
          </div>
          {schedule.detailed_description && (
            <div className={`col-lg-4 mission-levels-overview-tab py-2 `}>
              <h1>
                <span style={{ fontSize: "14pt", color: "#ffd889" }}>
                  Schedule Overview
                </span>
              </h1>{" "}
              <div
                dangerouslySetInnerHTML={{
                  __html: schedule.detailed_description,
                }}
                // className={`col-lg-4 mission-levels-overview-tab py-2 ${
                //   schedule.schedule_status === "locked" ? "not-started" : ""
                // }`}
                className={` `}
              ></div>
            </div>
          )}
          <div className="d-flex justify-content-between mt-3">
            <div className="w-100">
              {schedule?.audio_url && (
                <audio
                  src={s3baseUrl + schedule?.audio_url}
                  controls
                  className="w-100"
                />
              )}
            </div>
            {schedule.schedule_status == "completed" &&
              schedule.question_configration?.show_question &&
              schedule.question_configration?.button_text && (
                <>
                  {schedule.selected_questions.length > 0 && (
                    <button
                      className="mc-button-contained ms-4"
                      disabled={Boolean(schedule.schedule_status === "locked")}
                      onClick={() => setOpenModal(1)}
                    >
                      {schedule.question_configration?.button_text}
                    </button>
                  )}
                </>
              )}
          </div>
        </>
      )}
      {schedule.allowed_actions?.length > 0 && (
        <div className="col-12 position-relative">
          {schedule.schedule_status === "locked" && (
            <>
              {schedule.allowed_status !== "missed" && (
                <>
                  <Tooltip
                    title={`${
                      schedule.mission_status === "not_started"
                        ? "Start mission before unlock schedule"
                        : startedAt.isAfter(today, "day")
                        ? `You can't unlock it before ${dd_date_format_UPDATE(
                            schedule.started_at
                          )}`
                        : "Click to unlock"
                    }`}
                  >
                    <button
                      style={{ zIndex: "9" }}
                      variant="contained"
                      className={
                        schedule.schedule_status === "locked"
                          ? `small-contained-button mui-card-button d-flex centerAlign_new`
                          : "small-contained-button mui-card-button mui-card-button-program d-flex centerAlign_new"
                      }
                      onClick={() => handleUnlock(schedule)}
                    >
                      <img
                        src={videoShowLock}
                        alt=""
                        className="thumbnail-icon me-2 unlockIconNew "
                      />
                      <span>click here to unclock</span>
                    </button>
                  </Tooltip>
                </>
              )}
            </>
          )}
          <form
            className={` ${
              hide_Top_section && "p-0 mt-2 ps-1 pe-2 pb-3 "
            } allowed-actions-box pb-4`}
          >
            {!hide_Top_section && (
              <h2
                style={{ marginBottom: "14px", fontSize: "20px" }}
                // className={
                //   schedule.schedule_status === "locked"
                //     ? "heading-disabled"
                //     : " "
                // }
                className=""
              >
                {schedule.content_settings?.action_statement_heading}
              </h2>
            )}

            {schedule.allowed_actions.map((item, index) => {
              let { _id, action_statement, is_required, is_checked } = item;

              let find_answer = null;
              if (schedule.completed_allowed_actions?.length > 0) {
                find_answer = schedule.completed_allowed_actions.find(
                  (i) => i._id == _id
                );
              }

              let isDisabled = Boolean(schedule.schedule_status === "locked");

              return (
                <>
                  <Tooltip
                    title={
                      schedule.allowed_status == "missed" ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: replacedStringNote(
                              missionContentSettings?.missed_schedule_description
                            ),
                          }}
                          className="schedule_completed_description"
                        ></div>
                      ) : (
                        ""
                      )
                    }
                  >
                    <div
                      className={`mt-2 action-checkbox-box  ${
                        schedule.allowed_status == "missed"
                          ? " "
                          : isDisabled
                          ? "disabled"
                          : ""
                      }`}
                      onClick={() =>
                        schedule.allowed_status == "missed" ||
                        schedule.schedule_status === "locked" ||
                        schedule.schedule_status === "completed"
                          ? ""
                          : handleChangeAction(_id, isDisabled, schedule, item)
                      }
                    >
                      <div
                        className={`action-checkbox ${
                          is_checked ? "checked" : ""
                        }`}
                      >
                        {is_checked && <i class="fa-solid fa-check"></i>}
                      </div>
                      <div className="checkbox-label">{action_statement}</div>
                      <span className="required-asterisk ms-1">
                        {is_required && "*"}
                      </span>
                    </div>
                  </Tooltip>
                </>
              );
            })}
          </form>
        </div>
      )}
      {hide_Top_section && (
        <div className="text-end">
          {!find &&
            schedule.schedule_status !== "completed" &&
            schedule.allowed_status !== "missed" && (
              <>
                {schedule.schedule_status !== "locked" && (
                  <>
                    {!hideSubmitButton && (
                      <button
                        className="mc-button-contained mb-3"
                        disabled={
                          Boolean(schedule.schedule_status === "locked") ||
                          isSubmitting ||
                          find
                        }
                        onClick={(e) => openQuestionsPopup(e, schedule)}
                        // onClick={(e) => handleSubmit(e, schedule)}
                      >
                        {isSubmitting
                          ? "Submitting..."
                          : schedule.content_settings
                              ?.complete_schedule_button_text ||
                            "Complete Schedule"}
                      </button>
                    )}
                  </>
                )}
              </>
            )}
        </div>
      )}
      {!hide_Top_section &&
        !find &&
        schedule.schedule_status !== "completed" &&
        schedule.allowed_status !== "missed" && (
          <div className="text-end">
            {schedule.schedule_status !== "locked" && (
              <>
                {!hideSubmitButton && (
                  <button
                    className="mc-button-contained mb-3 mt-4"
                    disabled={
                      Boolean(schedule.schedule_status === "locked") ||
                      isSubmitting ||
                      find
                    }
                    // onClick={(e) => handleSubmit(e, schedule)}
                    onClick={(e) => openQuestionsPopup(e, schedule)}
                  >
                    {isSubmitting
                      ? "Submitting..."
                      : schedule.content_settings
                          ?.complete_schedule_button_text ||
                        "Complete Schedule"}
                  </button>
                )}
              </>
            )}
          </div>
        )}
      {!hide_Top_section && (
        <>
          <div className="col-12">
            {schedule.content_settings?.schedule_completed_description &&
              schedule.schedule_status == "completed" && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: replacedString(
                      schedule.content_settings?.schedule_completed_description
                    ),
                  }}
                  className="mt-4 schedule_completed_description"
                ></div>
              )}
          </div>
          {schedule.allowed_status === "missed" && (
            <div className="col-12">
              {missionContentSettings?.missed_schedule_description &&
                schedule.allowed_status == "missed" && (
                  <p
                    className="start-mission-note-new mt-3"
                    id="note_66a722347421ac375573836f"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: replacedStringNote(
                          missionContentSettings?.missed_schedule_description
                        ),
                      }}
                      className="schedule_completed_description"
                    ></div>
                  </p>
                )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
