import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import personFill from "@iconify/icons-eva/person-fill";
import copyFill from "@iconify/icons-eva/copy-fill";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import ChangePassword from "./ChangePassword";
import settings2Fill from "@iconify/icons-eva/settings-2-fill";
import { alpha } from "@mui/material/styles";
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
  Tooltip,
} from "@mui/material";
import MenuPopover from "../../components/MenuPopover";
import { _get_user_from_localStorage } from "src/DAL/localStorage/localStorage";
import { s3baseUrl } from "src/config/config";
import { get_root_value } from "src/utils/domUtils";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { string_avatar } from "src/utils/constants";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  _Delete_Account,
  _Delete_Account_Api_New,
} from "src/DAL/SupportTicket/Comments";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import ChangeAffliateId from "./ChangeAffliateId";
import ConfirmPassword from "src/components/ConfirmPassword";
import { member_logut_api } from "src/DAL/Login/Login";

const MENU_OPTIONS = [
  {
    label: "Edit Profile",
    icon: personFill,
    linkTo: "/edit-profile",
  },
];

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [openPasswordModal, setopenPasswordModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedValue, setSelectedValue] = useState("this_device");
  const [openDelete, setOpenDelete] = useState(false);
  const [openAffliateModal, setopenAffliateModal] = useState(false);

  const { pathname } = useLocation();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleProfile = (value) => {
    navigate(value);
    handleClose();
  };
  const { userInfo, STOP_SOCKET_FORCEFULLY } = useContentSetting();
  const { STOP_CHAT_FORCEFULLY, setChatList } = useChat();

  const handleLogout = async () => {
    setOpenConfirm(true);
  };

  const client_logut = async () => {
    setIsLoading(true);
    const result = await member_logut_api(selectedValue);
    if (result.code === 200) {
      localStorage.removeItem("user_data");
      localStorage.removeItem("token");
      STOP_SOCKET_FORCEFULLY();
      STOP_CHAT_FORCEFULLY();
      setChatList([]);
      navigate("/login");
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
  };

  const handleCopiedUrl = () => {
    enqueueSnackbar("ID copied to clipboard", { variant: "success" });
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await _Delete_Account_Api_New();
    if (result.code === 200) {
      localStorage.removeItem("user_data");
      localStorage.removeItem("token");
      navigate(`/login`);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleDelUser = () => {
    setOpenDelete(true);
    handleClose();
  };

  useEffect(() => {
    setTimeout(() => {
      const is_path_includes = (path) => {
        return pathname.toString().includes(path);
      };
      if (!is_path_includes("/source-session")) {
        localStorage.removeItem("search_source");
      }
      if (!is_path_includes("/study-session")) {
        localStorage.removeItem("studySessionList");
      }
      if (!is_path_includes("/study-session-recording")) {
        localStorage.removeItem("studySessionRecordingList");
      }
      if (!is_path_includes("/source-session")) {
        localStorage.removeItem("sourceSessionList");
      }
      if (!is_path_includes("/meditation-categories")) {
        localStorage.removeItem("maditationList");
      }
      if (!is_path_includes("/meditations")) {
        localStorage.removeItem("maditationss");
      }
      if (!is_path_includes("/affirmations")) {
        localStorage.removeItem("affirmation");
      }
      if (!is_path_includes("/shop/product/")) {
        localStorage.removeItem("shop");
      }
      if (!is_path_includes("/shop")) {
        localStorage.removeItem("shopCatagaory");
      }
      if (!is_path_includes("/live-events")) {
        localStorage.removeItem("portals");
      }
      if (
        !is_path_includes(
          "/mission-levels/level-details" && "/mission-levels/schedule-details/"
        )
      ) {
        localStorage.removeItem("tabValuesMission");
      }
      if (!is_path_includes("/memories")) {
        localStorage.removeItem("search_text_memories");
        localStorage.removeItem("sort_data");
      }
    }, 1000);
  }, [pathname]);
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Account?"}
        handleAgree={handleDelete}
      />
      <ChangeAffliateId
        openPasswordModal={openAffliateModal}
        setopenPasswordModal={setopenAffliateModal}
      />
      <ChangePassword
        openPasswordModal={openPasswordModal}
        setopenPasswordModal={setopenPasswordModal}
      />
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
            color: get_root_value("--portal-theme-primary"),
          }),
        }}
      >
        {userInfo !== null &&
        userInfo !== undefined &&
        userInfo !== "undefined" ? (
          <Avatar src={s3baseUrl + userInfo?.profile_image} alt="photoURL">
            {string_avatar(
              userInfo?.last_name
                ? userInfo?.first_name + " " + userInfo.last_name
                : userInfo?.first_name
            )}
          </Avatar>
        ) : (
          ""
        )}
      </IconButton>

      <MenuPopover
        Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        {userInfo !== null &&
        userInfo !== undefined &&
        userInfo !== "undefined" ? (
          <Box sx={{ my: 1.5, px: 2.5 }} color="inherit">
            <Typography variant="subtitle1" noWrap>
              {userInfo?.first_name + " " + userInfo?.last_name}
            </Typography>
            <Typography variant="body2" noWrap>
              {userInfo?.email}
            </Typography>
          </Box>
        ) : (
          ""
        )}
        <Divider sx={{ my: 1 }} />
        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => handleProfile(option.linkTo)}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
                color: get_root_value("--portal-theme-primary"),
              }}
            />

            {option.label}
          </MenuItem>
        ))}
        {
          <CopyToClipboard
            text={userInfo?.affiliate_url_name}
            onCopy={handleCopiedUrl}
          >
            <MenuItem key="" sx={{ typography: "body2", py: 1, px: 2.5 }}>
              <Box
                component={Icon}
                icon={copyFill}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24,
                  color: get_root_value("--portal-theme-primary"),
                }}
              />
              <Tooltip title="Click to copy ID" placement="left">
                <span>Copy Refferal Id</span>
              </Tooltip>
            </MenuItem>
          </CopyToClipboard>
        }
        <MenuItem
          key=""
          onClick={() => {
            handleClose();
            setopenAffliateModal(true);
          }}
          sx={{ typography: "body2", py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={"akar-icons:edit"}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
              color: get_root_value("--portal-theme-primary"),
            }}
          />

          <span>Change Affiliate Id</span>
        </MenuItem>
        <MenuItem
          key=""
          onClick={() => {
            navigate(`/payment-request`);
            handleClose();
          }}
          sx={{ typography: "body2", py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={settings2Fill}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
              color: get_root_value("--portal-theme-primary"),
            }}
          />
          Settings
        </MenuItem>
        <MenuItem
          key=""
          onClick={handleDelUser}
          sx={{
            typography: "body2",
            py: 1,
            px: 2.5,
            color: "red",
            fontWeight: 800,
          }}
        >
          <Box
            component={Icon}
            icon="material-symbols:delete-outline-sharp"
            sx={{
              mr: 2,
              width: 24,
              height: 24,
              color: "red",
            }}
          />
          Delete Account
        </MenuItem>
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={() => handleLogout()}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
      <ConfirmPassword
        open={openConfirm}
        setOpen={setOpenConfirm}
        selected={selectedValue}
        setSelected={setSelectedValue}
        handleAgree={client_logut}
        isLoading={isLoading}
      />
    </>
  );
}
