import { Avatar } from "@mui/material";
import React from "react";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import { s3baseUrl } from "src/config/config";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { get_date_with_user_time_zone } from "src/utils/constants";

export default function ParticipantInfoCard({ participant, MENU_OPTIONS }) {
  const { userInfo, adminTimeZone } = useContentSetting();

  return (
    <div className="post-creator user-info-card justify-content-between ps-2 mt-2">
      <div className="d-flex">
        <div className="like-profile-image">
          <Avatar src={s3baseUrl + participant.profile_image} alt="photoURL">
            {participant.first_name[0]}
          </Avatar>
        </div>
        <div className="creator-name ps-2 pt-1 text-start">
          <span className="d-flex feed-level">
            <h3 className="mb-0">{`${
              participant.first_name + " " + participant.last_name
            } ${participant._id === userInfo._id ? "(You)" : ""}`}</h3>
          </span>
          <span className="date-color">
            Joined at{" "}
            {get_date_with_user_time_zone(
              participant.join_time,
              "hh:mm A",
              userInfo.time_zone,
              adminTimeZone
            )}
          </span>
        </div>
      </div>
      <div className="pe-2">
        {participant.user_role === "participant" && (
          <CustomPopover menu={MENU_OPTIONS} data={participant} />
        )}
      </div>
    </div>
  );
}
