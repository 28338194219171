import { Avatar } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  mission_level_detail_New_api,
  mission_level_detail_New_api_load_more,
} from "src/DAL/MissionLevels/MissionLevels";
import { NotFoundNew } from "src/assets";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { s3baseUrl } from "src/config/config";
import { get_kmb_number } from "src/utils/formatNumber";

export default function LeaderBoardCoins({
  data,
  title,
  handleClickAll,
  streakLeaderBoard,
  setStreakLeaderBoard,
  loadMorePath,
  setLoadMorePath,
  leftHeight,
}) {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const observer = useRef();
  const { mission__id } = useParams();
  const get_mission_detail = async () => {
    let PostData = {
      mission_id: mission__id,
      tab: "dashboard",
      data: "coins_leader_board",
    };
    const result = await mission_level_detail_New_api_load_more(
      loadMorePath,
      PostData
    );
    if (result.code == 200) {
      let newArray = streakLeaderBoard.concat(result.streak_leader_board_stats);

      setStreakLeaderBoard(newArray);

      setLoadMorePath(result.coins_load_more);
      setIsLoadingMore(false);
    } else {
      setIsLoadingMore(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && loadMorePath) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, loadMorePath, observer]
  );
  const loadMoreData = () => {
    setIsLoadingMore(true);
    if (loadMorePath) {
      get_mission_detail(loadMorePath);
    }
  };
  return (
    <div
      className="card p-3 members-leader-board  "
      style={{ height: leftHeight ?? "400px", overflow: "auto" }}
    >
      <span className="challenge-heading unlock-color">{title}</span>
      <hr />
      {streakLeaderBoard.length > 0 ? (
        streakLeaderBoard.map((item, index) => {
          return (
            <div
              className={`d-flex justify-content-between align-items-center mt-2 ${
                index === 0 ? "colored-user border-changing-text" : ""
              }`}
              key={item._id}
            >
              <div
                className="d-flex align-items-center"
                style={{ width: "70%" }}
              >
                <Avatar
                  sx={{ width: 30, height: 30 }}
                  alt={item.user_info?.name}
                  src={s3baseUrl + item.user_info?.profile_image}
                />
                <div className="user-name ms-2">
                  {item.user_info?.first_name + " " + item.user_info?.last_name}
                </div>
              </div>
              <div className="user-coins" style={{ width: "30%" }}>
                {get_kmb_number(item.mission_attracted_coins)} Coins
              </div>
            </div>
          );
        })
      ) : (
        <>
          <RecordNotFound title="Data not found" show_new_image={true} />
        </>
      )}
      {loadMorePath ? (
        <div className="col-12 text-center">
          <button
            ref={lastBookElementRef}
            className="small-contained-button mt-3"
            // onClick={loadMoreData}
            // disabled={isLoadingMore}
          >
            {isLoadingMore ? "Loading..." : "Load More"}
          </button>
        </div>
      ) : (
        ""
      )}
      {handleClickAll && (
        <div className="text-end mt-2">
          <span className="view-all-boards" onClick={handleClickAll}>
            View all
          </span>
        </div>
      )}
    </div>
  );
}
