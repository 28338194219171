import React, { useCallback, useEffect, useRef, useState } from "react";
import { get_feeds_detail, get_feeds_list } from "src/DAL/Community/Community";
import CreatePost from "./CreatePost";
import OpenCreatePostCard from "./OpenCreatePostCard";
import PostCard from "./PostCard";
import { CircularProgress, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FeedDetailPopup from "./FeedDetailPopup";
import { useSnackbar } from "notistack";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import GeneralModelBox from "src/components/GeneralComponents/GeneralModelBox";
import FeedPollResult from "./components/FeedPoll/FeedPollResult";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "45%",
    marginTop: "20%",
    marginBottom: "20%",
  },
}));

function MainPart({
  showInfoBox,
  setShowInfoBox,
  userInfo,
  descriptions,
  feeds_type,
  event_id,
  feedSetting,
  userType,
  userRoadmap,
}) {
  const { socket } = useContentSetting();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [pageNumber, setPageNumber] = useState(0);
  const [totlePages, setTotlePages] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [feedsList, setFeedsList] = useState([]);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [likedData, setLikedData] = useState({});
  const [openPoll, setOpenPoll] = useState(false);
  const [isLoadingSection, setIsLoadingSection] = useState(true);
  const [feedType, setFeedType] = useState("general");
  const [formAction, setFormAction] = useState("ADD");
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [selectedFeed, setSelectedFeed] = useState({});
  const [loadMore, setLoadMore] = useState(
    `api/feeds/feed_list_with_pagination/v1?page=0&limit=10&feed_type=${feeds_type}&event=${
      event_id ? event_id : ""
    }&community_level=${userRoadmap}`
  );

  // popup states
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);

  const observer = useRef();

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totlePages > pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totlePages, pageNumber, loadMorePath, observer]
  );

  const handleCloseDetailBox = () => {
    setOpenDetailModal(false);
  };

  const handleClosePoll = () => {
    setOpenPoll(false);
    setSelectedFeed({});
  };

  const handleShowDetails = (post) => {
    setSelectedPost(post);
    setOpenDetailModal(true);
  };

  const handleSelectedImage = (post, index) => {
    setSelectedIndex(index);
    setSelectedPost(post);
    setOpenDetailModal(true);
  };

  const handleOpenImageBox = () => {
    setOpenCreateModal(true);
    setFeedType("image");
  };
  const handleOpenVideoBox = () => {
    setOpenCreateModal(true);
    setFeedType("video");
  };
  const handleOpenLiveVideoBox = () => {
    setOpenCreateModal(true);
    setFeedType("live");
  };

  const handleSuccessDelete = (post) => {
    setOpenDetailModal(false);
    const updated_feed = feedsList.filter((feed) => feed._id !== post._id);
    setFeedsList(updated_feed);
  };

  const handleSuccessUpdate = async (post) => {
    handleUpdateSpecificFeed(post._id);
    setOpenDetailModal(false);
  };

  const handleUpdateSpecificFeed = async (id) => {
    const result = await get_feeds_detail(id);

    if (result.code === 200) {
      const data = feedsList.map((feed) => {
        if (feed._id === id) {
          return { ...result.feeds, comment: result.feeds.comment_obj };
        } else {
          return feed;
        }
      });

      setFeedsList(data);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      // setIsLoading(false);
    }
  };

  const getFeedsListing = async (loadMorePath) => {
    const result = await get_feeds_list(loadMorePath);
    if (result.code === 200) {
      const new_feeds = result.feeds.map((feed) => {
        return { ...feed, is_show_all: false };
      });
      var newArray = feedsList.concat(new_feeds);

      setFeedsList(newArray);
      setPageNumber(pageNumber + 1);
      setTotlePages(result.total_pages);
      setLoadMorePath(result.load_more);
      setIsLoadingMore(false);
      setIsLoadingSection(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingSection(false);
      setIsLoadingMore(false);
    }
  };

  const getFeedsListingOnAddFeed = async (path) => {
    setIsLoadingSection(true);
    const result = await get_feeds_list(path);
    if (result.code === 200) {
      setFeedsList(result.feeds);
      setPageNumber(0);
      setTotlePages(result.total_pages);
      setLoadMorePath(result.load_more);
      setIsLoadingMore(false);
      setIsLoadingSection(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingMore(false);
      setIsLoadingSection(false);
    }
  };

  const getFeedsListingOnDelete = async (id) => {
    const data = feedsList.filter((feed) => feed._id !== id);
    setFeedsList(data);
  };

  const loadMoreData = () => {
    setIsLoadingMore(true);
    setIsLoadingMore(true);
    getFeedsListing(loadMorePath);
  };

  const handleOpenSimpleBox = () => {
    setOpenCreateModal(true);
    setFeedType("general");
  };

  const handleFeedEdit = (selected_feed) => {
    setSelectedFeed(selected_feed);
    setOpenCreateModal(true);
    setFormAction("EDIT");
  };

  const handlePollDetail = (feed) => {
    setSelectedFeed(feed);
    setOpenPoll(true);
  };

  const update_feed_likes_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id) {
          let set_feed = {
            ...feed,
            like_count: data.action_response.like_count,
            top_liked_user: data.action_response.top_liked_user,
            coins_count: data.action_response.coins_count,
          };
          if (userInfo._id == data.action_by) {
            set_feed.is_liked = data.action_response.is_liked;
          }
          return set_feed;
        } else {
          return feed;
        }
      });
      return feed_list;
    });
  };

  const update_feed_gratitude_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id && !feed.is_show_all) {
          let set_feed = {};
          set_feed = {
            ...feed,
            gratitude_count: data.action_response.gratitude_count,
            top_gratitude_user: data.action_response.top_gratitude_user,
            coins_count: data.action_response.coins_count,
          };
          if (userInfo._id == data.action_by) {
            set_feed.is_gratitude = data.action_response.is_gratitude;
          }
          return set_feed;
        } else {
          return feed;
        }
      });
      return feed_list;
    });
  };

  const update_comment_likes_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id) {
          if (feed.comment?.length > 0) {
            let action_comment = data.action_response.comment;
            let comments = feed.comment.map((comment) => {
              if (
                data.action_response.parent_comment &&
                comment?.child_comment
              ) {
                let child_comments = comment.child_comment.map((c_comment) => {
                  if (c_comment._id == action_comment) {
                    c_comment.like_count =
                      data.action_response.comment_like_count;
                    if (userInfo._id == data.action_by) {
                      c_comment.is_liked = data.action_response.is_liked;
                    }
                  }
                  return c_comment;
                });
                comment.child_comment = child_comments;
              } else {
                if (comment._id == action_comment) {
                  comment.like_count = data.action_response.comment_like_count;
                  if (userInfo._id == data.action_by) {
                    comment.is_liked = data.action_response.is_liked;
                  }
                }
              }
              return comment;
            });
            feed.comment = comments;
          }
        }
        return feed;
      });
      return feed_list;
    });
  };

  const add_comment_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id) {
          let new_comment = data.action_response.comment;
          if (userInfo._id == data.action_by) {
            new_comment.comment_is_self = true;
          }
          if (feed.comment?.length > 0) {
            feed.comment = [...feed.comment, new_comment];
          } else {
            feed.comment = [new_comment];
          }
          feed.comment_count = data.action_response.feed.comment_count;
        }
        return feed;
      });
      return feed_list;
    });
  };

  const add_comment_reply_by_socket = (data) => {
    let new_comment = data.action_response.comment;
    if (userInfo._id == new_comment.user_info_action_for.action_id) {
      new_comment.comment_is_self = true;
    }

    setFeedsList((feeds) => {
      return feeds.map((feed) => {
        if (feed._id === data.feed_id) {
          if (feed.comment?.length > 0) {
            let comments = feed.comment?.map((comment) => {
              if (comment._id == data.action_response.parent_comment) {
                let child_comments = comment.child_comment;
                let find_c = child_comments.find(
                  (c) => c._id == new_comment._id
                );
                if (!find_c) {
                  child_comments = [new_comment, ...comment.child_comment];
                  comment.child_comments_count++;
                }
                comment.child_comment = child_comments;
              }
              return comment;
            });
            feed.comment = comments;
            feed.comment_count = data.action_response.feed.comment_count;
          }
        }
        return feed;
      });
    });
  };

  const edit_comment_reply_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id) {
          let comments = feed.comment;
          if (comments?.length > 0) {
            comments = comments.map((comment) => {
              let new_comment = data.action_response.comment;
              if (comment._id == new_comment.parent_comment) {
                let child_comments = comment.child_comment;
                if (child_comments?.length > 0) {
                  child_comments = child_comments.map((child_comment) => {
                    if (child_comment._id == data.action_response.comment._id) {
                      let is_liked = child_comment.is_liked;
                      child_comment = data.action_response.comment;
                      let comment_is_self = false;
                      if (userInfo._id == data.action_by) {
                        comment_is_self = true;
                      }
                      child_comment.comment_is_self = comment_is_self;
                      child_comment.is_liked = is_liked;
                    }
                    return child_comment;
                  });
                }
                comment.child_comment = child_comments;
              }
              return comment;
            });
          }
          feed.comment = comments;
        }
        return feed;
      });
      return feed_list;
    });
  };

  const update_comment_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id) {
          let comments = feed.comment;
          if (comments?.length > 0) {
            comments = comments?.map((comment) => {
              let new_comment = data.action_response.comment;
              if (comment._id == new_comment._id) {
                let comment_is_self = false;
                if (userInfo._id == data.action_by) {
                  comment_is_self = true;
                }
                new_comment.comment_is_self = comment_is_self;
                new_comment.is_liked = comment.is_liked;
                comment = new_comment;
              }
              return comment;
            });
          }
          feed.comment = comments;
        }
        return feed;
      });
      return feed_list;
    });
  };

  const delete_comment_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id == data.feed_id && feed.comment.length > 0) {
          let comments = feed.comment.filter(
            (item) => item._id !== data.comment
          );
          feed.comment = comments;
          feed.comment_count = data.action_response.feed.comment_count;
        }
        return feed;
      });
      return feed_list;
    });
  };

  const handle_poll_answered = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id == data.feed_obj._id) {
          feed.poll_info = data.feed_obj.poll_info;
          if (data.action_by._id === userInfo._id) {
            feed.selected_options = data.feed_obj.selected_options;
          }
        }
        return feed;
      });
      return feed_list;
    });
  };

  const handle_poll_expired = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        let find_feed = data.feeds.find((item) => item.feed_id === feed._id);
        if (find_feed) {
          feed.poll_info.poll_status = "expired";
        }
        return feed;
      });
      return feed_list;
    });
  };

  const delete_comment_reply_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id) {
          let comments = feed.comment;
          if (comments?.length > 0) {
            comments = comments.map((signle_comment) => {
              if (signle_comment?.child_comment?.length > 0) {
                const array_filtered = signle_comment.child_comment.filter(
                  (comment) => comment._id !== data.comment
                );
                signle_comment.child_comment = array_filtered;
              }
              return signle_comment;
            });
          }
          feed.comment = comments;
          feed.comment_count = data.action_response.feed.comment_count;
        }
        return feed;
      });
      return feed_list;
    });
  };

  const end_stream_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.module_id) {
          let set_feed = {};
          set_feed = {
            ...feed,
            is_live_streaming: false,
          };
          return set_feed;
        } else {
          return feed;
        }
      });
      return feed_list;
    });
  };

  const handleUpdatePoll = (feed_obj) => {
    setFeedsList((old) =>
      old.map((item) => {
        if (item._id === feed_obj._id) {
          item = feed_obj;
        }
        return item;
      })
    );
  };

  useEffect(() => {
    socket.emit("live_event_room", "live_feed_room");

    socket.on("end_stream_event_listener", (data) => {
      end_stream_by_socket(data);
    });
    socket.on("live_feed_room_reciever", (data) => {
      console.log(data, "live_feed_room_reciever");
      if (data.action === "feedlike" || data.action === "feedunlike") {
        update_feed_likes_by_socket(data);
      } else if (data.action === "gratitude") {
        update_feed_gratitude_by_socket(data);
      } else if (
        data.action === "commentlike" ||
        data.action === "commentunlike"
      ) {
        update_comment_likes_by_socket(data);
      } else if (data.action === "add_comment") {
        add_comment_by_socket(data);
      } else if (data.action === "edit_comment") {
        update_comment_by_socket(data);
      } else if (data.action === "delete_comment") {
        delete_comment_by_socket(data);
      } else if (data.action === "add_comment_reply") {
        add_comment_reply_by_socket(data);
      } else if (data.action === "edit_comment_reply") {
        edit_comment_reply_by_socket(data);
      } else if (data.action === "delete_comment_reply") {
        delete_comment_reply_by_socket(data);
      } else if (data.action === "poll_answered") {
        handle_poll_answered(data);
      } else if (data.action === "poll_expired") {
        handle_poll_expired(data);
      }
    });

    return () => {
      socket.off("live_feed_room_reciever");
    };
  }, [openDetailModal]);

  useEffect(() => {
    let list_path = `api/feeds/feed_list_with_pagination/v1?page=0&limit=10&feed_type=${feeds_type}&event=${
      event_id ? event_id : ""
    }&community_level=${userRoadmap}`;
    getFeedsListingOnAddFeed(list_path);
  }, [userRoadmap]);

  return (
    <>
      <OpenCreatePostCard
        handleOpenSimpleBox={handleOpenSimpleBox}
        userInfo={userInfo}
        handleOpenImageBox={handleOpenImageBox}
        handleOpenVideoBox={handleOpenVideoBox}
        handleOpenLiveVideoBox={handleOpenLiveVideoBox}
      />
      {isLoadingSection ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        feedsList &&
        feedsList.map((feeds_list, index) => {
          return (
            <PostCard
              showInfoBox={showInfoBox}
              setShowInfoBox={setShowInfoBox}
              feeds_list={feeds_list}
              index={index}
              userInfo={userInfo}
              descriptions={descriptions}
              getFeedsListingOnDelete={getFeedsListingOnDelete}
              handleShowDetails={handleShowDetails}
              handleFeedEdit={handleFeedEdit}
              getFeedsListingOnAddFeed={getFeedsListingOnAddFeed}
              handleUpdateSpecificFeed={handleUpdateSpecificFeed}
              feeds_type={feeds_type}
              key={index}
              feedSetting={feedSetting}
              handleSelectedImage={handleSelectedImage}
              event_id={event_id}
              handleUpdatePoll={handleUpdatePoll}
              handlePollDetail={handlePollDetail}
            />
          );
        })
      )}
      {!isLoadingSection && totlePages > pageNumber ? (
        <div className="col-12 text-center favourite-buttton-box">
          <button
            ref={lastBookElementRef}
            className="small-contained-button mt-3"
            onClick={loadMoreData}
            id="load-more-feed"
          >
            {isLoadingMore ? "Loading..." : "Load More"}
          </button>
        </div>
      ) : (
        ""
      )}

      <CreatePost
        setFeedType={setFeedType}
        feedType={feedType}
        openCreateModal={openCreateModal}
        setOpenCreateModal={setOpenCreateModal}
        getFeedsListing={getFeedsListingOnAddFeed}
        userInfo={userInfo}
        selectedFeed={selectedFeed}
        formAction={formAction}
        setFormAction={setFormAction}
        handleSuccessUpdate={handleSuccessUpdate}
        feeds_type={feeds_type}
        event_id={event_id}
        userType={userType}
        feedSetting={feedSetting}
        userRoadmap={userRoadmap}
      />

      <Modal
        open={openDetailModal}
        onClose={handleCloseDetailBox}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="feed-detail-popup"
      >
        <>
          <div className="row post-detail-box">
            {!selectedPost?.is_live_streaming && (
              <span
                className="cross-show-all-likes-model"
                onClick={handleCloseDetailBox}
              >
                x
              </span>
            )}
          </div>
          <FeedDetailPopup
            handleCloseDetailBox={handleCloseDetailBox}
            post_id={selectedPost._id}
            feed={selectedPost}
            getFeedsListingOnAddFeed={handleUpdateSpecificFeed}
            handleSuccessDelete={handleSuccessDelete}
            handleSuccessUpdate={handleSuccessUpdate}
            feeds_type={feeds_type}
            event_id={event_id}
            openDetailModal={openDetailModal}
            feedSetting={feedSetting}
            selectedImageIndex={selectedIndex}
            handleUpdatePoll={handleUpdatePoll}
          />
        </>
      </Modal>

      <GeneralModelBox
        open={openPoll}
        setOpen={setOpenPoll}
        className="feed-poll-popup-modal"
        componentToPassDown={
          <FeedPollResult
            selectedFeed={selectedFeed}
            handleClosePoll={handleClosePoll}
            openPoll={openPoll}
          />
        }
      />
    </>
  );
}

export default MainPart;
