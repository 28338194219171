import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

export default function SkeletonColor() {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div className="ms-2 me-2">
        <Skeleton
          sx={{
            borderRadius: "10px",
            bgcolor: "#1d1c1d",
            "&::before": { bgcolor: "#2b2b2b" },
          }}
          variant="rectangular"
          width={210}
          height={280}
          animation="wave"
        />
      </div>{" "}
      <div className="ms-2 me-2">
        <Skeleton
          sx={{
            borderRadius: "10px",
            bgcolor: "#1d1c1d",
            "&::before": { bgcolor: "#2b2b2b" },
          }}
          variant="rectangular"
          width={210}
          height={280}
          animation="wave"
        />
      </div>{" "}
      <div className="ms-2 me-2">
        <Skeleton
          sx={{
            borderRadius: "10px",
            bgcolor: "#1d1c1d",
            "&::before": { bgcolor: "#2b2b2b" },
          }}
          variant="rectangular"
          width={210}
          height={280}
          animation="wave"
        />
      </div>{" "}
      <div className="ms-2 me-2">
        <Skeleton
          sx={{
            borderRadius: "10px",
            bgcolor: "#1d1c1d",
            "&::before": { bgcolor: "#2b2b2b" },
          }}
          variant="rectangular"
          width={210}
          height={280}
          animation="wave"
        />
      </div>{" "}
    </Box>
  );
}
