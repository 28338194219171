import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useSnackbar } from "notistack";
import { IconButton, CircularProgress, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { mission_level_detail_New_api } from "src/DAL/MissionLevels/MissionLevels";
import { s3baseUrl } from "src/config/config";
import MainDashBoard from "./MainDashBoard";
import MissionFeedDetailNew from "./MissionFeedDetailNew";
import { get_kmb_number } from "src/utils/formatNumber";
import MissionLevelDetail from "./MissionLevelDetail";
import SchedulesList from "./SchedulesList";
import MobileLeaderBoaerd from "./MobileLeaderBoaerd";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import MissionReward from "./MissionReward";
import { reward } from "src/assets";
import MissionReport from "./MissionReport";
import PieMissionChart from "./PieMissionChart";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

let status_object = {
  0: "dashboard",
  1: "",
  2: "community",
  3: "report",
  4: "your_schedules",
};
export default function MissionDetailNew() {
  const { mission__id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingfirst, setIsLoadingfirst] = useState(true);
  const [missionDetail, setMissionDetail] = useState({});
  const [missionDashboaerd, setmissionDashboaerd] = useState(null);
  const [streakLeaderBoard, setStreakLeaderBoard] = useState([]);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [coinLeaderBoard, setCoinLeaderBoard] = useState([]);
  const [loadMorePathCoin, setLoadMorePathCoin] = useState("");
  const [communityMission, setCommunityMission] = useState(null);
  const [graphData, setGraphData] = useState([]);
  const [missionCommunity, setMissionCommunity] = useState([]);
  const [missonSchedulesList, SetMIssionSchedulesList] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [missionSchedules, setMissionSchedules] = useState(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [loadCompletePage, setloadCompletePage] = useState(false);
  const [missionLevel, setMissionLevel] = useState(null);
  const [mcqData, setMcqData] = useState([]);
  const [open, setOpen] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [graphStructure, setGraphStructure] = useState([]);

  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    localStorage.setItem("tabValuesMission", newValue);
  };

  const get_mission_detail = async (value) => {
    setIsLoading(true);
    let filter_By = status_object[value ? value : tabValue];
    let PostData = {
      mission_id: mission__id,
      tab: filter_By,
      data: "all",
    };
    const result = await mission_level_detail_New_api(PostData);
    if (result.code == 200) {
      setMissionDetail(result.mission);
      setMissionCommunity(result.dashboard_community);
      setmissionDashboaerd(result);
      setMissionLevel(result.mission_level);
      setStreakLeaderBoard(result.streak_leader_board_stats);
      setCoinLeaderBoard(result.coins_leader_board_stats);
      SetMIssionSchedulesList(result.mission_schedules);
      setLoadMorePath(result.streak_load_more);
      setLoadMorePathCoin(result.coins_load_more);
      setMissionSchedules(result.current_mission_schedule);
      setMcqData(result.mcq_graph_data);
      if (result.structured_graph_data.length > 0) {
        setGraphStructure(result.structured_graph_data);
      }
      setReportData(result.report_data);
      let data = {
        other_missions: result.other_missions,
        user_continue_missions: result.user_continue_missions,
      };

      setGraphData(result.graph_data);

      setCommunityMission(data);
      setIsLoading(false);
      setIsLoadingfirst(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsLoadingfirst(false);
    }
  };

  const TABS_OPTIONS = [
    {
      title: "Dashboard",
      component: (
        <>
          {isLoading ? (
            <CircularProgress className={classes.loading} color="primary" />
          ) : (
            <MainDashBoard
              missionDashboaerd={missionDashboaerd}
              streakLeaderBoard={streakLeaderBoard}
              missionDetail={missionDetail}
              missionLevel={missionLevel}
              setStreakLeaderBoard={setStreakLeaderBoard}
              loadMorePath={loadMorePath}
              setLoadMorePath={setLoadMorePath}
              missonSchedulesList={missonSchedulesList}
              loadMorePathCoin={loadMorePathCoin}
              setLoadMorePathCoin={setLoadMorePathCoin}
              coinLeaderBoard={coinLeaderBoard}
              setCoinLeaderBoard={setCoinLeaderBoard}
              graphData={graphData}
              mcqData={mcqData}
              missionCommunity={missionCommunity}
              missionSchedules={missionSchedules}
              graphStructure={graphStructure}
            />
          )}
        </>
      ),
    },
    {
      title: "Mission Overview",
      component: <MissionLevelDetail hideHeading={true} />,
    },
    {
      title: "Community",
      component: (
        <>
          {isLoading ? (
            <CircularProgress className={classes.loading} color="primary" />
          ) : (
            <MissionFeedDetailNew
              streakLeaderBoard={streakLeaderBoard}
              setStreakLeaderBoard={setStreakLeaderBoard}
              loadMorePath={loadMorePath}
              setLoadMorePath={setLoadMorePath}
              coinLeaderBoard={coinLeaderBoard}
              setCoinLeaderBoard={setCoinLeaderBoard}
              loadMorePathCoin={loadMorePathCoin}
              setLoadMorePathCoin={loadMorePathCoin}
              communityMission={communityMission}
            />
          )}
        </>
      ),
    },
    {
      title: "Mission Report",
      component: (
        <>
          {isLoading ? (
            <CircularProgress className={classes.loading} color="primary" />
          ) : (
            <MissionReport
              reportData={reportData}
              graphData={graphData}
              mcqData={mcqData}
              graphStructure={graphStructure}
            />
          )}
        </>
      ),
    },
  ];
  if (isMobile) {
    TABS_OPTIONS.push({
      title: "Mission Leader Board",
      component: (
        <>
          {isLoading ? (
            <CircularProgress className={classes.loading} color="primary" />
          ) : (
            <MobileLeaderBoaerd
              missionDashboaerd={missionDashboaerd}
              streakLeaderBoard={streakLeaderBoard}
              setStreakLeaderBoard={setStreakLeaderBoard}
              loadMorePath={loadMorePath}
              setLoadMorePath={setLoadMorePath}
              loadMorePathCoin={loadMorePathCoin}
              setLoadMorePathCoin={loadMorePathCoin}
              coinLeaderBoard={coinLeaderBoard}
              setCoinLeaderBoard={setCoinLeaderBoard}
              graphData={graphData}
              missionCommunity={missionCommunity}
            />
          )}
        </>
      ),
    });
  }

  useEffect(() => {
    if (tabValue !== 1 || tabValue !== 3) {
      get_mission_detail(tabValue);
    }
  }, [tabValue]);
  useEffect(() => {
    let value = Number(localStorage.getItem("tabValuesMission"));
    if (value) {
      setTabValue(value);
      if (value !== 1 || value !== 3) {
        get_mission_detail(value);
      }
    }
  }, []);
  const handleNavigate = () => {
    navigate("/mission-levels");
  };

  if (isLoadingfirst) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mobile-margin">
          <div className="col-12">
            <div className="d-flex challenge-video-detail-title">
              <span>
                <IconButton
                  className="back-screen-button"
                  onClick={() => navigate("/mission-levels")}
                >
                  <ArrowBackIcon />
                </IconButton>
              </span>

              <div className="detail-container mt-2">
                <h3 className="ms-3">{missionDetail.title}</h3>
              </div>
            </div>
          </div>

          <div className="col-12 ">
            {/* <div className="card p-3 h-100">
              <div className="main-container d-md-block">
                <div className="image-container">
                  <img
                    src={s3baseUrl + missionDetail?.image?.thumbnail_2}
                    style={{ width: "100%" }} // Ensures the image is 100% wide on all screens
                  />
                </div>

                <div className="detail-container mt-2">
                  <h3 className="ms-3">{missionDetail.title}</h3>
                  <p className="mb-0 ms-3">
                    {missionDetail?.short_description}
                  </p>
                </div>
              </div>
            </div> */}
          </div>

          <div
            className="col-12 mt-2 d-block  level-icons position-relative "
            style={{ zIndex: "999" }}
          >
            {/* {missionDetail.mission_road_map_level &&
              missionDetail.mission_road_map_level.badges &&
              missionDetail.mission_road_map_level.badges.length > 0 && (
                <span
                  onClick={() => setOpen(true)}
                  className="cursor-pointer"
                  style={{
                    position: "absolute",
                    right: "3%",
                    top: "7px",
                    zIndex: "9999",
                  }}
                >
                  <Tooltip title="View Reward Details">
                    <p className="reward-details-popup">
                      <img src={reward} alt="" />
                    </p>
                  </Tooltip>
                </span>
              )} */}
            <MUICustomTabs
              data={TABS_OPTIONS}
              value={tabValue}
              handleChange={handleChange}
              className="challenge-video-detail-tabs"
            />
          </div>
        </div>
      </div>
    </>
  );
}
