import React from "react";
import { Box, Card, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopover from "./CustomPopover";
import { get_date_with_user_time_zone } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

const ImageStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "auto",
  objectFit: "cover",
});

// ----------------------------------------------------------------------

export default function MemoriesCard({
  data,
  handleClick,
  MENU_OPTIONS,
  className,
  lockStatement,
}) {
  const { userInfo, adminTimeZone } = useContentSetting();
  return (
    <div className={`row ${className}`}>
      {data.map((item, index) => {
        return (
          <div className={item.card_classes} key={index}>
            <Card className="mui-custom-card h-100" sx={{ cursor: "pointer" }}>
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                {item.card_button && item.card_button.button_title && (
                  <button
                    variant="contained"
                    className={
                      lockStatement
                        ? `small-contained-button mui-card-button d-flex centerAlign ${
                            index == 0 ? "centerAlign0" : ""
                          }`
                        : "small-contained-button mui-card-button mui-card-button-program d-flex centerAlign"
                    }
                    onClick={
                      item.card_button.handleClick
                        ? () => {
                            item.card_button.handleClick(item);
                          }
                        : undefined
                    }
                  >
                    {item.card_button.button_title}
                  </button>
                )}
                <ImageStyle
                  alt={item.title}
                  src={item.card_image}
                  onClick={
                    handleClick
                      ? () => {
                          handleClick(item);
                        }
                      : undefined
                  }
                />
                {MENU_OPTIONS && (
                  <div className="mui-custom-card-dots">
                    <CustomPopover menu={MENU_OPTIONS} data={item} />
                  </div>
                )}
              </Box>
              <div className="pinterest-card text-end">
                {item.pinterestDate && (
                  <span>
                    {get_date_with_user_time_zone(
                      item.pinterestDate,
                      "DD MMM YYYY",
                      adminTimeZone,
                      userInfo.time_zone
                    )}
                  </span>
                )}
              </div>
              <Stack spacing={2} className="mui-custom-card-content">
                <h3
                  onClick={
                    handleClick
                      ? () => {
                          handleClick(item);
                        }
                      : undefined
                  }
                >
                  {htmlDecode(item.card_title)}
                </h3>
                {item.card_short_description && (
                  <p className="mui-custom-card-description">
                    {htmlDecode(item.card_short_description)}
                  </p>
                )}
                {item.card_short_description_2 && (
                  <p className="card_lower_title_description">
                    {htmlDecode(item.card_short_description_2)}
                  </p>
                )}
                {item.card_gray_description && (
                  <p className="mui_gray_description">
                    <span>{item.card_gray_description}</span>
                  </p>
                )}
              </Stack>
            </Card>
          </div>
        );
      })}
    </div>
  );
}
