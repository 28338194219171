import React, { useRef, useState, useEffect } from "react";

const RecordFrame = () => {
  const [isRecording, setIsRecording] = useState(false);
  const canvasRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const recordedChunks = useRef([]);
  const videoStreamRef = useRef(null); // Ref to hold the video stream

  // Function to start recording
  const startRecording = async () => {
    try {
      // Get audio stream from the microphone
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      // Get video stream from the webcam
      const videoStream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });

      videoStreamRef.current = videoStream; // Save the video stream

      // Create a canvas element and set its dimensions
      const canvas = canvasRef.current;
      const canvasStream = canvas.captureStream(30); // Capture the canvas at 30 FPS

      // Combine audio and video streams
      const combinedStream = new MediaStream([
        ...canvasStream.getVideoTracks(),
        ...audioStream.getAudioTracks(),
        ...videoStream.getVideoTracks(),
      ]);

      // Set up MediaRecorder
      mediaRecorderRef.current = new MediaRecorder(combinedStream, {
        mimeType: "video/webm; codecs=vp8", // Specify the codec
      });

      // Handle data available and stop events
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunks.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = handleStopRecording;

      // Start recording
      mediaRecorderRef.current.start();
      setIsRecording(true);

      // Start drawing the video on the canvas
      drawVideo([videoStream, videoStream]);
    } catch (error) {
      console.error("Error accessing media devices.", error);
    }
  };

  // Function to handle stopping recording
  const handleStopRecording = () => {
    const blob = new Blob(recordedChunks.current, { type: "video/webm" });
    const url = URL.createObjectURL(blob);

    // Create a download link for the recorded video
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = "recording.webm";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    // Clear the recorded chunks
    recordedChunks.current = [];
  };

  const drawVideo = (videoStreams) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Create video elements for each stream
    const videos = videoStreams.map((stream) => {
      const video = document.createElement("video");
      video.srcObject = stream;
      video.play();
      return video;
    });

    const render = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas before redrawing

      // Draw each video and overlay text
      videos.forEach((video, index) => {
        const x = (index % 2) * (canvas.width / 2); // X position
        const y = Math.floor(index / 2) * (canvas.height / 2); // Y position
        ctx.drawImage(video, x, y, canvas.width / 2, canvas.height / 2); // Draw video
        ctx.font = "24px Arial";
        ctx.fillStyle = "white";
        ctx.fillText(`Video ${index + 1}`, x + 10, y + 30); // Position the text
      });

      requestAnimationFrame(render);
    };

    render(); // Start rendering video frames
  };

  // Function to stop recording
  const stopRecording = () => {
    setIsRecording(false);
    mediaRecorderRef.current?.stop();
  };

  return (
    <div>
      <h1>Record Canvas with Audio</h1>
      <canvas
        ref={canvasRef}
        width="400"
        height="300"
        style={{ border: "1px solid #ccc" }}
      />
      <button onClick={startRecording} disabled={isRecording}>
        Start Recording
      </button>
      <button onClick={stopRecording} disabled={!isRecording}>
        Stop Recording
      </button>
    </div>
  );
};

export default RecordFrame;
