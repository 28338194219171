import { Avatar, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { videoShowLock } from "src/assets";
import ImageBox from "src/components/GeneralComponents/ImageBox";
import { s3baseUrl } from "src/config/config";

export default function MissionsJourney({ title, data }) {
  const [imageUrl, setImageUrl] = useState(null);
  const navigate = useNavigate();
  const { mission__id } = useParams();
  const handleClose = () => {
    setImageUrl(null);
  };
  return (
    <div
      className="card p-3 members-leader-board mb-1"
      style={{
        maxHeight: "350px",
        overflow: "auto",
      }}
    >
      {imageUrl && (
        <ImageBox open={true} handleClose={handleClose} image_url={imageUrl} />
      )}
      <span className="challenge-heading unlock-color">{title}</span>
      <hr />
      {/* <div className="d-flex justify-content-between flex-wrap">
        {data &&
          data.length > 0 &&
          data.map((item, index) => {
            return (
              <>
                {item?.content_settings?.show_in_mission_journey && (
                  <div
                    className="journey-item position-relative"
                    style={{
                      cursor:
                        item.schedule_status == "locked"
                          ? "not-allowed "
                          : "pointer",
                    }}
                    onClick={() => {
                      if (item.schedule_status == "locked") {
                        return;
                      } else {
                        navigate(
                          `/mission-levels/schedule-details/${mission__id}/${item._id}`,
                          {
                            state: item,
                          }
                        );
                      }
                    }}
                  >
                    {item.schedule_status == "locked" && (
                      <img src={videoShowLock} className="lock-icon-journey" />
                    )}
                    <div
                      style={{
                        opacity: item.schedule_status == "locked" ? 0.5 : 1,
                      }}
                    >
                      <img
                        src={
                          s3baseUrl +
                          item?.content_settings?.mission_journey_image
                        }
                      />
                      <h3
                        className=" mb-0 mt-2 challenge-heading"
                        style={{ fontWeight: 500, fontSize: "12px" }}
                      >
                        {item?.content_settings?.mission_journey_title}
                      </h3>
                    </div>
                  </div>
                )}
              </>
            );
          })}
      </div> */}
      <div className="d-flex justify-content-between flex-wrap">
        {data &&
          data.length > 0 &&
          data.map((item, index) => {
            return (
              <>
                {item?.content_settings?.show_in_mission_journey && (
                  <div
                    className="journey-item position-relative"
                    style={{
                      cursor:
                        item.schedule_status == "locked"
                          ? "not-allowed "
                          : "pointer",
                    }}
                  >
                    {item.schedule_status == "locked" && (
                      <img src={videoShowLock} className="lock-icon-journey" />
                    )}
                    <div
                      style={{
                        // opacity: item.schedule_status == "locked" ? 0.5 : 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* <img
                        src={
                          s3baseUrl +
                          item?.content_settings?.mission_journey_image
                        }
                      /> */}
                      <Tooltip
                        title={
                          item.schedule_status == "locked" ? "" : "View Image"
                        }
                      >
                        <Avatar
                          src={
                            s3baseUrl +
                            item?.content_settings?.mission_journey_image
                          }
                          className="journey-item-avatar"
                          onClick={() => {
                            if (item.schedule_status == "locked") {
                              return;
                            } else {
                              setImageUrl(
                                s3baseUrl +
                                  item?.content_settings?.mission_journey_image
                              );
                            }
                          }}
                        />
                      </Tooltip>
                      <h3
                        className=" mb-0 ms-2 challenge-heading"
                        style={{ fontWeight: 500, fontSize: "14px" }}
                        onClick={() => {
                          if (item.schedule_status == "locked") {
                            return;
                          } else {
                            navigate(
                              `/mission-levels/schedule-details/${mission__id}/${item._id}`,
                              {
                                state: item,
                              }
                            );
                          }
                        }}
                      >
                        {item?.content_settings?.mission_journey_title}
                      </h3>
                    </div>
                  </div>
                )}
              </>
            );
          })}
      </div>
    </div>
  );
}
