import { CircularProgress, IconButton } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { _dynamite_event_category_video_list_chat } from "src/DAL/Inception/Inception";
import { htmlDecode } from "src/utils/convertHtml";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import Community from "../Community/Community";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
var moment_tz = require("moment-timezone");
import {
  get_live_event_detail,
  handleFeatureStatus,
  handle_delete_message,
  handle_send_message,
  handle_update_message,
  handle_validate_redirect,
  receive_delete_live_message_by_socket,
  receive_feature_status_by_socket,
  receive_new_live_message_by_socket,
  receive_update_live_message_by_socket,
  scroll_to_bottom,
} from "./live_event_functions";
import { useNavigate, useParams } from "react-router-dom";
import ChatCard from "./components/Chat-Box/ChatCard";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import CategoryCard from "./components/CategoryCard";
import DashboardNotificationCard from "src/components/_dashboard/app/DashboardNotificationCard";
import EventConfigCard from "src/components/_dashboard/app/EventConfigCard";
import moment from "moment";
import { get_date_with_user_time_zone } from "src/utils/constants";
import ShowEventCommentLikes from "../Community/components/LikesPopup/ShowEventCommentLikes";
import EventVideos from "../Community/EventVideos";
import TwitterFeeds from "../Community/TwitterFeeds";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function LiveEventsDetail() {
  const { userInfo, socket, adminTimeZone } = useContentSetting();

  const {
    get_users_list_against_event,
    handleSetIsChatShow,
    handleSetEventSlug,
    setOfflinePortalMembers,
    setOnlinePortalMembers,
    setOnlinePortalMembersCount,
    setOfflinePortalMembersCount,
    onlinePortalMembers,
    offlinePortalMembers,
  } = useChat();
  const [showChat, setShowChat] = useState(true);
  const [newChat, setNewChat] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const [parentMessage, setParentMessage] = useState({});
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [eventDetail, setEventDetail] = useState();
  const [isMemberFree, setIsMemberFree] = useState();
  const [liveChat, setLiveChat] = useState([]);
  const [pinnedComments, setPinnedComments] = useState([]);
  const [currentEventsArray, setCurrentEventsArray] = useState([]);
  const [likesArray, setLikesArray] = useState([]);
  const [openLikesModal, setOpenLikesModal] = useState(false);
  const [upcomingEventsArray, setUpcomingEventsArray] = useState([]);
  const [chatMessage, setChatMessage] = useState("");
  const [image, setImage] = React.useState("");
  const [oldImage, setOldImage] = React.useState("");
  const [previews, setPreviews] = useState("");
  const [selectedComment, setSelectedComment] = useState({});
  const [formType, setFormType] = useState("ADD");
  const [openDelete, setOpenDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isEventAvailable, setIsEventAvailable] = useState(true);
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [remainTime, setRemainTime] = useState(0);
  const [eventDate, setEventDate] = useState(null);
  const [showTimer, setShowTimer] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleEmojiSelect = (emoji) => {
    setChatMessage((msg) => msg + emoji.native);
  };

  const handleTabClick = (e, row) => {
    if (row.tab_slug == "the-source") {
      window.history.replaceState(null, null, `/live-events/${params.id}`);
    } else {
      window.history.replaceState(
        null,
        null,
        `/live-events/${params.id}/${row.tab_slug}`
      );
    }
  };

  const handleChangeTabs = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleOpenReplyBox = (object) => {
    setParentMessage(object);
    setIsReply(true);
  };

  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };

  const handleRemoveAll = () => {
    handleRemove();
    setChatMessage("");
    setIsSubmitting(false);
    setParentMessage({});
    setFormType("ADD");
    setIsReply(false);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setShowEmojiPicker(false);
    handle_send_message(
      image,
      eventDetail,
      chatMessage,
      userInfo,
      isReply,
      parentMessage,
      socket
    );
    handleRemoveAll();
  };

  const handleMessageUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setShowEmojiPicker(false);
    handle_update_message(
      eventDetail,
      oldImage,
      previews,
      image,
      chatMessage,
      socket,
      selectedComment
    );
    handleRemoveAll();
  };

  const handleDeleteLiveComment = async () => {
    handle_delete_message(eventDetail, selectedComment, socket);
    setOpenDelete(false);
  };

  const handleCommentEdit = (comment) => {
    setSelectedComment(comment);
    setChatMessage(comment.message);
    if (comment.file_url) {
      setPreviews(s3baseUrl + comment.file_url);
      setOldImage(comment.file_url);
    } else {
      setImage("");
      setPreviews("");
      setOldImage("");
    }
    setFormType("EDIT");
  };

  const handleCommentPin = (comment) => {
    handleFeatureStatus(comment, eventDetail, socket);
  };

  const handleCommentDelete = (comment) => {
    setSelectedComment(comment);
    setOpenDelete(true);
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };
  const getOneDigit = (digi) => {
    let reminder = digi % 10;
    let deviser = digi / 10;
    let decimalPart = Math.floor(deviser);
    return { reminder, decimalPart };
  };

  const handleLikeComment = (comment, parentComment) => {
    const event_id = eventDetail._id;
    const comment_id = comment._id;
    const video_id = eventDetail.feature_video._id;
    const member = {
      _id: userInfo._id,
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      profile_image: userInfo.profile_image,
    };

    let socket_object = {
      event_id,
      video_id,
      member,
      comment_id,
    };

    if (parentComment) {
      socket_object.parent_message = parentComment._id;
    }
    socket.emit("live_event_message_like", socket_object);
  };

  const handleAskQuestion = () => {
    if (eventDetail) {
      let question_configration =
        eventDetail.feature_video?.question_configration;
      if (
        question_configration &&
        question_configration.show_question &&
        question_configration.button_text
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const handleLikeParentComment = (data) => {
    setLiveChat((old) => {
      return old.map((old_comment) => {
        let like_count = old_comment.like_count;
        let is_liked = old_comment.is_liked;
        if (data.action == "like") {
          like_count = like_count + 1;
        } else {
          like_count = like_count - 1;
        }

        if (data.member._id == userInfo._id) {
          is_liked = !is_liked;
        }

        if (old_comment._id == data.comment_id) {
          return {
            ...old_comment,
            like_count: like_count,
            is_liked: is_liked,
          };
        } else {
          return old_comment;
        }
      });
    });
    setPinnedComments((old) => {
      return old.map((old_comment) => {
        let like_count = old_comment.like_count;
        let is_liked = old_comment.is_liked;
        if (data.action == "like") {
          like_count = like_count + 1;
        } else {
          like_count = like_count - 1;
        }

        if (data.member._id == userInfo._id) {
          is_liked = !is_liked;
        }

        if (old_comment._id == data.comment_id) {
          return {
            ...old_comment,
            like_count: like_count,
            is_liked: is_liked,
          };
        } else {
          return old_comment;
        }
      });
    });
  };

  const handleLikeChildComment = (data) => {
    setLiveChat((old) => {
      return old.map((old_comment) => {
        if (old_comment._id == data.parent_message) {
          let replies = old_comment.replies;
          let new_replies = replies.map((reply) => {
            if (reply._id == data.comment_id) {
              let is_liked = reply.is_liked;
              let like_count = reply.like_count;
              if (data.action == "like") {
                like_count = like_count + 1;
              } else {
                like_count = like_count - 1;
              }

              if (data.member._id == userInfo._id) {
                is_liked = !is_liked;
              }

              return {
                ...reply,
                like_count: like_count,
                is_liked: is_liked,
              };
            } else {
              return reply;
            }
          });
          return {
            ...old_comment,
            replies: new_replies,
          };
        } else {
          return old_comment;
        }
      });
    });
    setPinnedComments((old) => {
      return old.map((old_comment) => {
        if (old_comment._id == data.parent_message) {
          let replies = old_comment.replies;
          let new_replies = replies.map((reply) => {
            if (reply._id == data.comment_id) {
              let is_liked = reply.is_liked;
              let like_count = reply.like_count;
              if (data.action == "like") {
                like_count = like_count + 1;
              } else {
                like_count = like_count - 1;
              }

              if (data.member._id == userInfo._id) {
                is_liked = !is_liked;
              }

              return {
                ...reply,
                like_count: like_count,
                is_liked: is_liked,
              };
            } else {
              return reply;
            }
          });
          return {
            ...old_comment,
            replies: new_replies,
          };
        } else {
          return old_comment;
        }
      });
    });
  };

  const handle_like_message_receiver = (data) => {
    if (data.parent_message) {
      handleLikeChildComment(data);
    } else {
      handleLikeParentComment(data);
    }
  };

  const handleOpenLikes = (comment) => {
    setSelectedComment(comment);
    setOpenLikesModal(true);
  };

  const get_update_time_with_time_zone = (date) => {
    let new_date = get_date_with_user_time_zone(
      date,
      "YYYY-MM-DD HH:mm:ss",
      userInfo.time_zone,
      adminTimeZone
    );
    return Date.parse(new_date);
  };
  let deadline1 = eventDetail?.event_timer_configration?.event_date
    ? moment(eventDetail?.event_timer_configration.event_date).format(
        "YYYY-MM-DD"
      )
    : "";
  let fDate = deadline1
    ? deadline1 + " " + eventDetail?.event_timer_configration?.event_time
    : "";

  let date_now = moment();
  const currentTimeInZone = date_now
    .tz(userInfo.time_zone)
    .format("YYYY-MM-DD HH:mm:ss");

  const deadline = moment(fDate);
  const currentTime = moment(currentTimeInZone);

  const getTime = () => {
    const time = deadline ? Date.parse(deadline) - Date.parse(currentTime) : "";
    const timeRemains = deadline
      ? Date.parse(deadline) - get_update_time_with_time_zone(Date.now())
      : "";
    setRemainTime(timeRemains);
    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    setEventDate(deadline?._i);

    let timea = new Date(eventDate?._i).getTime();
    let timeb = new Date().getTime();
    let resultAccess = timea.toString() > timeb.toString();

    setShowTimer(resultAccess);
    if (resultAccess == false) {
      setRemainTime(0);
      navigate(`/live-events-locked/${eventDetail._id}`);
    } else {
      new Date(eventDate).getTime() >= new Date().getTime();
      const interval = setInterval(() => getTime(deadline), 1000);
      return () => clearInterval(interval);
    }
  }, [deadline]);

  useEffect(() => {
    if (eventDetail) {
      // event handling
      socket.emit("live_event_room", eventDetail._id);
      socket.on("live_event_message_receiver", (data) => {
        receive_new_live_message_by_socket(
          data,
          eventDetail,
          userInfo,
          setLiveChat,
          setNewChat
        );
      });
      socket.on("live_event_message_like_receiver", (data) => {
        handle_like_message_receiver(data);
      });

      socket.on("live_event_message_update_receiver", (data) => {
        receive_update_live_message_by_socket(
          data,
          userInfo,
          setLiveChat,
          setPinnedComments
        );
      });

      socket.on("live_event_message_delete_receiver", (data) => {
        receive_delete_live_message_by_socket(
          data,
          setLiveChat,
          setPinnedComments
        );
      });

      socket.on("make_message_featured_unfeatured_receiver", (data) => {
        receive_feature_status_by_socket(
          data,
          eventDetail,
          setEventDetail,
          setLiveChat,
          setPinnedComments,
          setNewChat,
          setShowScrollArrow,
          userInfo,
          setIsLoading
        );
      });
    }

    return () => {
      socket.off("member_online_for_portal");
      socket.off("live_event_message_receiver");
      socket.off("live_event_message_like_receiver");
      socket.off("live_event_message_update_receiver");
      socket.off("live_event_message_delete_receiver");
      socket.off("make_message_featured_unfeatured_receiver");
    };
  }, [eventDetail]);

  useEffect(() => {
    get_live_event_detail(
      params.id,
      setIsEventAvailable,
      params.category_slug,
      setTabValue,
      setEventDetail,
      setIsMemberFree,
      setCurrentEventsArray,
      setUpcomingEventsArray,
      setShowChat,
      setIsLoading,
      setLiveChat,
      setPinnedComments,
      setNewChat,
      setShowScrollArrow,
      userInfo
    );
    setInterval(function () {
      setRefresh((r) => !r);
    }, 10000);
    return () => {
      handleSetIsChatShow(false);
    };
  }, []);

  useEffect(() => {
    if (!isEventAvailable) {
      navigate(`/live-events`);
    }
  }, [isEventAvailable]);

  useEffect(() => {
    setTimeout(() => {
      setRefresh((r) => !r);
    }, 0);

    window.scrollTo(0, 0);
  }, [showChat]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  if (!isEventAvailable) {
    return <RecordNotFound title="Live Event Not Found" />;
  }

  const TABS_OPTIONS = [
    {
      title: "The Source",
      tab_slug: "the-source",
      onClick: handleTabClick,
      component: (
        <div className="new-community-page mt-3">
          <Community
            feeds_type="event"
            event_id={eventDetail._id}
            event_category={eventDetail.dynamite_event_category}
            current_events_array={currentEventsArray}
            upcoming_events_array={upcomingEventsArray}
            event_slug={params.id}
            eventDetail={eventDetail}
          />
        </div>
      ),
    },
  ];

  if (screen.width < 571) {
    TABS_OPTIONS.push(
      {
        title: "Videos",
        tab_slug: "videos",
        onClick: handleTabClick,
        component: (
          <div className="new-community-page ps-2 mt-3">
            <EventVideos
              feeds_type="event"
              event_category={eventDetail.dynamite_event_category}
              event_id={eventDetail._id}
              event_slug={params.id}
            />
          </div>
        ),
      },
      {
        title: "Events",
        tab_slug: "events",
        onClick: handleTabClick,
        component: (
          <div className="new-community-page ps-2 mt-3">
            <TwitterFeeds
              upcomingEvents={upcomingEventsArray}
              currenEvents={currentEventsArray}
              userInfo={userInfo}
              feeds_type="event"
              eventDetail={eventDetail}
            />
          </div>
        ),
      }
    );
  }

  if (eventDetail.dynamite_event_category.length > 0) {
    eventDetail.dynamite_event_category.map((category) => {
      TABS_OPTIONS.push({
        title: category.title,
        tab_slug: category.dynamite_event_category_slug,
        onClick: handleTabClick,
        component: (
          <CategoryCard category={category} handleOpenLikes={handleOpenLikes} />
        ),
      });
    });
  }

  const handleVideoQuestions = () => {
    navigate(
      `/live-events/video/question-answers/${eventDetail.feature_video._id}`
    );
  };

  console.log(eventDetail, "eventDetaileventDetaileventDetaileventDetail");

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete live comment?"}
        handleAgree={handleDeleteLiveComment}
      />
      <ShowEventCommentLikes
        open={openLikesModal}
        setOpen={setOpenLikesModal}
        comment_id={selectedComment._id}
      />
      {eventDetail.title === null ||
        (eventDetail.title === undefined ? (
          <RecordNotFound title="Live Event Not Found" />
        ) : (
          <div className="container-fluid ps-0 ps-lg-2 ">
            <span className="d-flex mb-3" style={{ alignItems: "baseline" }}>
              <IconButton
                className="back-screen-button back-buuton-profile pt-2"
                onClick={() => navigate(`/live-events`)}
              >
                <ArrowBackIcon />
              </IconButton>
              <h2 className="mt-3 ms-2">
                <strong>{htmlDecode(eventDetail.title)}</strong>
              </h2>
            </span>
            <div className="row align-items-center ps-2">
              <div
                id="video-col"
                className={showChat ? "col-12 col-lg-8" : "col-12"}
              >
                {/* {eventDetail.event_logo && (
                  <img
                    className="m-auto"
                    src={s3baseUrl + eventDetail.event_logo?.thumbnail_1}
                    alt=""
                  />
                )} */}
                {/* {!eventDetail.event_logo && ( */}
                {/* <h2>
                  <strong>{htmlDecode(eventDetail.title)}</strong>
                </h2> */}
                {/* )} */}
                {eventDetail.feature_video?.video_url ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: eventDetail.feature_video.video_url,
                    }}
                  ></div>
                ) : eventDetail.video_url ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: eventDetail.video_url,
                    }}
                  ></div>
                ) : (
                  <img src={s3baseUrl + eventDetail.images?.thumbnail_1}></img>
                )}
              </div>

              {showChat && (
                <ChatCard
                  eventDetail={eventDetail}
                  pinnedComments={pinnedComments}
                  handleCommentDelete={handleCommentDelete}
                  handleCommentEdit={handleCommentEdit}
                  handleCommentPin={handleCommentPin}
                  liveChat={liveChat}
                  handleOpenReplyBox={handleOpenReplyBox}
                  newChat={newChat}
                  setNewChat={setNewChat}
                  setShowScrollArrow={setShowScrollArrow}
                  showScrollArrow={showScrollArrow}
                  isReply={isReply}
                  chatMessage={chatMessage}
                  setChatMessage={setChatMessage}
                  formType={formType}
                  handleRemoveAll={handleRemoveAll}
                  previews={previews}
                  handleRemove={handleRemove}
                  handleUpload={handleUpload}
                  isSubmitting={isSubmitting}
                  handleSendMessage={handleSendMessage}
                  handleMessageUpdate={handleMessageUpdate}
                  setIsLoading={setIsLoading}
                  setShowEmojiPicker={setShowEmojiPicker}
                  handleLikeComment={handleLikeComment}
                  handleOpenLikes={handleOpenLikes}
                />
              )}
              {showEmojiPicker && (
                <div className="col-12 col-lg-4 mt-4 mt-lg-0 ms-auto">
                  <Picker
                    data={data}
                    onEmojiSelect={handleEmojiSelect}
                    theme="dark"
                    dynamicWidth={true}
                  />
                </div>
              )}
              <div className="col-12 text-end pe-3 mt-4 mb-3">
                {handleAskQuestion() && (
                  <button
                    className="small-contained-button"
                    onClick={handleVideoQuestions}
                  >
                    {
                      eventDetail.feature_video.question_configration
                        ?.button_text
                    }
                  </button>
                )}
                {eventDetail.feature_video?.is_feature === true &&
                  eventDetail.feature_video?.is_chat_enable === true &&
                  (showChat ? (
                    <button
                      onClick={() => setShowChat(false)}
                      className="ms-3 comment-submit-button"
                    >
                      Hide Chat
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setShowChat(true);
                        setTimeout(() => {
                          scroll_to_bottom(setNewChat, setShowScrollArrow);
                        }, 500);
                      }}
                      className="ms-3 comment-submit-button"
                    >
                      Show Chat
                    </button>
                  ))}
              </div>

              {eventDetail.feature_video?.audio_file_url &&
                eventDetail.feature_video?.video_type === "audio" && (
                  <div className="col-12 text-center">
                    <div>
                      <audio
                        controlsList="nodownload"
                        className="w-100"
                        src={
                          s3baseUrl + eventDetail.feature_video.audio_file_url
                        }
                        controls
                      />
                    </div>
                  </div>
                )}
            </div>
            <div className="row">
              <div className="col-md-6 mb-3 mb-md-0">
                {eventDetail.banner1_image &&
                  eventDetail.banner1_image.thumbnail_1 && (
                    <a
                      style={{ cursor: "pointer" }}
                      className="mt-4"
                      onClick={() =>
                        handle_validate_redirect(
                          eventDetail,
                          eventDetail.banner1_link,
                          setIsLoading,
                          true
                        )
                      }
                    >
                      <img
                        src={s3baseUrl + eventDetail.banner1_image.thumbnail_1}
                      ></img>
                    </a>
                  )}
                {eventDetail?.banner1_button_text &&
                  eventDetail?.banner1_link && (
                    <div className="text-center mt-3">
                      <button
                        onClick={() =>
                          handle_validate_redirect(
                            eventDetail,
                            eventDetail.banner1_link,
                            setIsLoading,
                            true
                          )
                        }
                        class="join-today-btn fw-16 mt-0"
                      >
                        {eventDetail?.banner1_button_text}
                      </button>
                    </div>
                  )}
              </div>
              <div className="col-md-6 mb-3 mb-md-0">
                {eventDetail.banner2_image &&
                  eventDetail.banner2_image.thumbnail_1 && (
                    <a
                      style={{ cursor: "pointer" }}
                      className="mt-4"
                      onClick={() =>
                        handle_validate_redirect(
                          eventDetail,
                          eventDetail?.banner2_link,
                          setIsLoading,
                          true
                        )
                      }
                    >
                      <img
                        src={s3baseUrl + eventDetail.banner2_image.thumbnail_1}
                      ></img>
                    </a>
                  )}
                {eventDetail?.banner2_button_text &&
                  eventDetail?.banner2_link && (
                    <div className="text-center mt-3">
                      <button
                        onClick={() =>
                          handle_validate_redirect(
                            eventDetail,
                            eventDetail?.banner2_link,
                            setIsLoading,
                            true
                          )
                        }
                        class="join-today-btn fw-16 mt-0"
                      >
                        {eventDetail?.banner2_button_text}
                      </button>
                    </div>
                  )}
              </div>
              {isMemberFree ==
                eventDetail?.event_timer_configration?.show_for ||
              eventDetail?.event_timer_configration?.show_for == "all"
                ? eventDetail.event_timer_configration &&
                  eventDetail.event_timer_configration.is_enable == true && (
                    <div className="mt-4">
                      {/* {eventDetail?.event_timer_configration && (
                      <div className="col-12 col-md-6 mb-4 w-100">
                        <EventConfigCard
                          data={eventDetail?.event_timer_configration}
                        />
                      </div>
                    )} */}
                      {eventDetail.event_timer_configration &&
                        eventDetail.event_timer_configration.is_enable &&
                        remainTime > 0 && (
                          <>
                            {showTimer && (
                              <div className="text-start label-text pt-5 countdown-timer">
                                <h4>Countdown Timer</h4>
                              </div>
                            )}
                            <div className="row justify-content-between">
                              {showTimer && (
                                <div className="col-md-6 pt-3">
                                  <div className="timer-count d-flex justify-content-between">
                                    <div className="time-count-container">
                                      <div className="countEvent">
                                        {getOneDigit(days).decimalPart}
                                      </div>
                                    </div>
                                    <div className="time-count-container">
                                      <div className="countEvent">
                                        {getOneDigit(days).reminder}
                                      </div>
                                    </div>
                                    <div className="colon">:</div>
                                    <div className="time-count-container">
                                      <div className="countEvent">
                                        {getOneDigit(hours).decimalPart}
                                      </div>
                                    </div>
                                    <div className="time-count-container">
                                      <div className="countEvent">
                                        {getOneDigit(hours).reminder}
                                      </div>
                                    </div>
                                    <div className="colon">:</div>
                                    <div className="time-count-container">
                                      <div className="countEvent">
                                        {getOneDigit(minutes).decimalPart}
                                      </div>
                                    </div>
                                    <div className="time-count-container">
                                      <div className="countEvent">
                                        {getOneDigit(minutes).reminder}
                                      </div>
                                    </div>
                                    <div className="colon">:</div>
                                    <div className="time-count-container">
                                      <div className="countEvent">
                                        {getOneDigit(seconds).decimalPart}
                                      </div>
                                    </div>
                                    <div className="time-count-container">
                                      <div className="countEvent">
                                        {getOneDigit(seconds).reminder}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between time-container-site">
                                    <div className="time-detail">Days</div>
                                    <div className="time-detail">Hours</div>
                                    <div className="time-detail">Minutes</div>
                                    <div className="time-detail">Seconds</div>
                                  </div>
                                </div>
                              )}
                              <div className="col-md-6">
                                {eventDetail?.event_timer_configration && (
                                  <EventConfigCard
                                    data={eventDetail?.event_timer_configration}
                                  />
                                )}
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                  )
                : ""}
              <div className="col-12 mt-3">
                {eventDetail.feature_video?.detail_description ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: eventDetail.feature_video.detail_description,
                    }}
                  ></div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: eventDetail.detail_description,
                    }}
                  ></div>
                )}
              </div>
            </div>
            <div className="mt-3">
              <MUICustomTabs
                data={TABS_OPTIONS}
                value={tabValue}
                handleChange={handleChangeTabs}
              />
            </div>
          </div>
        ))}
    </>
  );
}

export default LiveEventsDetail;
