import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import {
  _get_is_seen_video_localStorage,
  _get_user_from_localStorage,
} from "src/DAL/localStorage/localStorage";
import { LoadingButton } from "@mui/lab";
import {
  add_guest_user_to_meeting,
  live_meeting_by_meeting_id_api,
} from "src/DAL/LiveStream/LiveStream";
import LiveMeeting from "../LiveMeeting";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import moment from "moment";
import { isTimePassedUTC } from "../../../utils/constants";
import BeforeStartMeeting from "./BeforeStartMeeting";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Background = ({ imageUrl }) => (
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: `url(${imageUrl})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      zIndex: 1,
      borderRadius: "20px",
    }}
  />
);

let micError =
  "Microphone access is denied. Please grant permission in your browser settings.";
let cameraError =
  "'Camera permission is denied. Please enable it in your browser settings.'";

const constraints = {
  video: {
    width: 1280,
    height: 720,
    aspectRatio: 16 / 9,
  },
  audio: true,
};

let EMPTY_VALUES = {
  name: "",
  password: "",
};

export default function JoinMeeting() {
  const myVideo = useRef();
  const classes = useStyles();
  const { meeting_id } = useParams();
  const [myStream, setMyStream] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isMuted, setIsMuted] = useState(true);
  const [isPaused, setIsPaused] = useState(true);
  const [isRerender, setIsRerender] = useState(true);
  const [inputs, setInputs] = useState(EMPTY_VALUES);
  const [showPassword, setShowPassword] = useState(false);
  const [liveStream, setLiveStream] = useState({});
  const [meetingDetail, setMeetingDetail] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isReadyJoin, setIsReadyJoin] = useState(false);
  const [isTimePassed, setIsTimePassed] = useState(false);
  const [pageState, setPageState] = useState(0);

  const {
    START_SOCKET_FORCEFULLY,
    handleUserInfo,
    userInfo,
    STOP_SOCKET_FORCEFULLY,
    socket,
  } = useContentSetting();

  const handleMuteToggle = () => {
    if (myStream) {
      const audioTracks = myStream.getAudioTracks();
      audioTracks.forEach((track) => (track.enabled = isMuted));
      setIsMuted(!isMuted);
    }
  };

  const handlePlayPauseToggle = async () => {
    if (myStream) {
      const videoTracks = myStream.getVideoTracks();
      videoTracks.forEach((track) => (track.enabled = isPaused));
      setIsPaused(!isPaused);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    socket.disconnect();
    setLoading(true);
    let postData = {};
    if (isLoggedIn) {
      postData.name = userInfo.first_name + " " + userInfo.last_name;
      postData.user_id = userInfo._id;
      postData.user_type = "member_user";
    } else {
      postData.name = inputs.name;
      postData.user_type = "guest_user";
      if (inputs._id) {
        postData.user_id = inputs._id;
      }
    }
    if (meetingDetail.meeting_password) {
      postData.password = inputs.password;
    }
    const result = await add_guest_user_to_meeting(meeting_id, postData);
    if (result.code === 200) {
      localStorage.setItem(`guest_user_data`, JSON.stringify(result.user));
      setMeetingDetail(result.meeting);
      setLiveStream(result.live_stream);
      START_SOCKET_FORCEFULLY(result.user._id);
      handleUserInfo(result.user);
      if (result.live_stream.is_live) {
        setIsReadyJoin(true);
      } else {
        const {
          meeting_time_zone,
          meeting_start_date,
          meeting_start_time,
          after_meeting_start_content,
          before_meeting_start_content,
        } = result.meeting;
        const is_time_passed = isTimePassedUTC(
          meeting_start_date + " " + meeting_start_time,
          meeting_time_zone
        );

        if (
          (is_time_passed && after_meeting_start_content?.is_show_content) ||
          (!is_time_passed && before_meeting_start_content?.is_show_content)
        ) {
          setPageState(2);
        } else {
          setPageState(1);
        }
        setIsTimePassed(is_time_passed);
      }

      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  const getMeetingDetail = async () => {
    const result = await live_meeting_by_meeting_id_api(meeting_id);
    if (result.code === 200) {
      setMeetingDetail(result.meeting);
      const _get_user_info = _get_user_from_localStorage();
      if (_get_user_info?.first_name) {
        setIsLoggedIn(true);
      } else {
        let guest_user_data = JSON.parse(
          localStorage.getItem("guest_user_data")
        );
        if (guest_user_data?.first_name) {
          setInputs((old) => ({
            ...old,
            name: guest_user_data.first_name,
            _id: guest_user_data._id,
          }));
        }
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((old) => ({ ...old, [name]: value }));
  };

  const handleLogout = () => {
    localStorage.removeItem("user_data");
    localStorage.removeItem("token");
    STOP_SOCKET_FORCEFULLY();
    setIsLoggedIn(false);
  };

  const handleJoin = () => {
    setIsReadyJoin(true);
  };

  const meeting_session_started = (data) => {
    console.log(data, "meeting_session_started");
    setLiveStream(data.live_Stream);
  };

  const end_stream_event_listener = (data) => {
    if (data.module_id === meeting_id) {
      setPageState(0);
      setIsFullScreen(false);
      setLiveStream({});
      setTimeout(() => {
        window.location.reload();
      }, 500);
      enqueueSnackbar("Meeting ended.", { variant: "info" });
    }
  };

  const handleStart = () => {
    setPageState(2);
  };

  useEffect(() => {
    getMeetingDetail();
  }, []);

  useEffect(() => {
    if (pageState === 0) return;
    socket.on("connect", () => {
      socket.emit("join_meeting_room", meeting_id);
    });
    socket.on("meeting_session_started", meeting_session_started);
    socket.on("end_stream_event_listener", end_stream_event_listener);
    return () => {
      socket.off("meeting_session_started");
      socket.off("end_stream_event_listener");
    };
  }, [pageState]);

  useEffect(async () => {
    if (!myVideo.current || isLoading) return;
    const mic = await navigator.permissions.query({ name: "microphone" });
    if (mic.state === "denied") {
      alert(micError);
      return;
    }

    const camera = await navigator.permissions.query({ name: "camera" });
    if (camera.state === "denied") {
      alert(cameraError);
      return;
    }

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        const videoTracks = stream.getTracks();
        videoTracks.forEach((track) => (track.enabled = false));
        setMyStream(stream);
        myVideo.current.srcObject = stream;
      })
      .catch((err) => {
        console.error("Failed to get user media:", err);
      });
  }, [myVideo, isLoading, isRerender]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const streamData = {
    feedDetail: meetingDetail,
    liveStream,
    isFullScreen,
    setIsFullScreen,
    joinType: "participant",
    setLiveStream,
    user_type: isLoggedIn ? "member_user" : "guest_user",
    userInfo,
    module_name: "meeting",
    onLeaveMeeting: () => {
      setPageState(0);
      setIsFullScreen(false);
      setLiveStream({});
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
  };

  if (liveStream?.is_live && isReadyJoin) {
    if (myStream) {
      myStream.getTracks().forEach((track) => track.stop());
    }
    return (
      <div className="container mt-5">
        <div className="row py-4">
          <div className="col-12">
            <h2>{htmlDecode(meetingDetail.title)}</h2>
            {meetingDetail.description && (
              <p>{htmlDecode(meetingDetail.description)}</p>
            )}
            <LiveMeeting {...streamData} />
          </div>
        </div>
      </div>
    );
  }

  if (pageState === 2) {
    return (
      <div className="container mt-5">
        <div className="row py-4">
          <div className="col-12">
            <div className="d-flex justify-content-between">
              <div className="d-flex mb-2">
                <IconButton
                  className="back-screen-button me-2"
                  onClick={() => setPageState(0)}
                >
                  <ArrowBackIcon />
                </IconButton>
                <h2>{htmlDecode(meetingDetail.title)}</h2>
              </div>
              {liveStream?.is_live && (
                <LoadingButton
                  size="large"
                  variant="contained"
                  className="loagin-button"
                  onClick={handleJoin}
                >
                  Join Now
                </LoadingButton>
              )}
            </div>
            {meetingDetail.description && (
              <p>{htmlDecode(meetingDetail.description)}</p>
            )}
            <BeforeStartMeeting
              moduleDetail={meetingDetail}
              isStart={isTimePassed}
              setIsTimePassed={setIsTimePassed}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container join-meeting-page mt-5 pt-5">
      <div className="row w-100">
        <div className="col-12 col-lg-7 d-flex align-items-center justify-content-center">
          <div className="w-100">
            <div className="position-relative">
              {isPaused && (
                <Background
                  imageUrl={s3baseUrl + meetingDetail.image.thumbnail_1}
                />
              )}
              <video ref={myVideo} autoPlay playsInline></video>
              <div className="meeting-testing-controls">
                <div className="audio-video-controls">
                  <button className="audio" onClick={handleMuteToggle}>
                    <Icon
                      className="stream-control-icon"
                      icon={`${
                        isMuted ? "material-symbols:mic-off" : "ic:outline-mic"
                      }`}
                    />
                  </button>
                  <button
                    className="video ms-1"
                    onClick={handlePlayPauseToggle}
                  >
                    <Icon
                      className="stream-control-icon"
                      icon={`iconoir:video-camera${isPaused ? "-off" : ""}`}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {pageState === 0 ? (
          <div className="col-12 col-lg-5">
            <div className="p-4">
              <div className="mb-3 join-meeting-login">
                <h4>{htmlDecode(meetingDetail.title)}</h4>
                {meetingDetail.description && (
                  <p>{htmlDecode(meetingDetail.description)}</p>
                )}
              </div>

              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  name="name"
                  label="Enter Name"
                  required
                  value={
                    isLoggedIn
                      ? userInfo.first_name + " " + userInfo.last_name
                      : inputs.name
                  }
                  onChange={handleChange}
                  disabled={isLoggedIn}
                />

                {meetingDetail.meeting_password && (
                  <TextField
                    fullWidth
                    name="password"
                    autoComplete="current-password"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    required
                    value={inputs.password}
                    onChange={handleChange}
                    className="mt-3"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {isLoggedIn && (
                  <div className="use-guest-user mt-2">
                    <span onClick={handleLogout}>Join As Guest User</span>
                  </div>
                )}
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  className={`loagin-button ${isLoggedIn ? "mt-1" : "mt-3"}`}
                  loading={loading}
                >
                  Join
                </LoadingButton>
              </form>
            </div>
          </div>
        ) : (
          <div className="col-12 col-lg-5">
            <div className="p-4">
              <div className="mb-3 join-meeting-login">
                <h4>{htmlDecode(meetingDetail.title)}</h4>
                {meetingDetail.description && (
                  <p>{htmlDecode(meetingDetail.description)}</p>
                )}

                {!liveStream?.is_live && (
                  <span className="d-flex align-items-center mt-2">
                    Waiting for the host to start the meeting{" "}
                    <CircularProgress
                      style={{
                        height: 16,
                        width: 16,
                        marginLeft: 5,
                      }}
                    />
                  </span>
                )}
                {!liveStream?.is_live &&
                  meetingDetail.meeting_start_date_time && (
                    <div class="mt-2 meeting-start-date-time">
                      <span>Scheduled:</span>{" "}
                      {moment(
                        meetingDetail.meeting_start_date,
                        "YYYY-MM-DD"
                      ).format("DD-MM-YYYY") +
                        " " +
                        moment(
                          meetingDetail.meeting_start_time,
                          "HH:mm:ss"
                        ).format("hh:mm A")}{" "}
                      <span>({meetingDetail.meeting_time_zone})</span>
                    </div>
                  )}
              </div>
              {liveStream?.is_live && (
                <LoadingButton
                  fullWidth
                  size="large"
                  variant="contained"
                  className="loagin-button mt-3"
                  onClick={handleJoin}
                >
                  Join Now
                </LoadingButton>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-12">
          <div className="text-center p-5 mt-5">
            <p>
              &copy;2024 Dynamite Lifestyle Communications, Inc. All rights
              reserved. Privacy & Legal Policies | Send Report
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
