import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import RecordRTC from "recordrtc";

const HtmlRecorder = () => {
  const videoRef = useRef(null);
  const [recorder, setRecorder] = useState(null);
  const [stream, setStream] = useState(null);
  const [chunkIndex, setChunkIndex] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [isUploadEnabled, setIsUploadEnabled] = useState(false);
  const { topic } = useParams();

  useEffect(() => {
    const initCamera = async () => {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      videoRef.current.srcObject = mediaStream;
      setStream(mediaStream);
    };

    initCamera();
  }, []);

  const startRecording = () => {
    const newRecorder = RecordRTC(stream, {
      type: "video",
      mimeType: "video/webm",
      timeSlice: 5000,
      ondataavailable: sendRecordingChunk,
    });

    newRecorder.startRecording();
    setRecorder(newRecorder);
    setIsRecording(true);
    setIsUploadEnabled(false);
  };

  const stopRecording = () => {
    recorder.stopRecording(() => {
      const blob = recorder.getBlob();
      const videoURL = URL.createObjectURL(blob);
      videoRef.current.srcObject = null;
      videoRef.current.src = videoURL;
      videoRef.current.controls = true;

      // Save the video file locally
      const link = document.createElement("a");
      link.href = videoURL;
      link.download = "recorded-video.webm";
      link.click();

      setIsUploadEnabled(true);
    });

    setIsRecording(false);
  };

  const handleDownload = (blob) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "screen-recording.mp4";
    a.click();
    URL.revokeObjectURL(url);
  };

  const sendRecordingChunk = async (blob) => {
    if (blob instanceof Blob) {
      handleDownload(blob);
      const formData = new FormData();
      formData.append("file", blob, `chunk_${chunkIndex}.webm`);
      formData.append("module_id", topic);
      // formData.append("is_final_chunk", "true");

      const token =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzQxNTMzZTY0YzFjYzY2YzlhMTZhMzgiLCJsb2dpbl90b2tlbiI6IjU0NTI0MjcwLTcwZWEtMTFlZi05OTIxLTc1OWRlYjJjMjNiZSIsImxvZ2luX2J5IjoibWVtYmVyX3VzZXIiLCJpYXQiOjE3MjYxMzM3MjF9.OFVz7xYuxq47MnJ6wfWYL2zglaLaeBiCR1hOMLWXXi0"; // Replace with your token

      try {
        const response = await fetch(
          "https://apidevfeatures.dynamitelifestyle.com/api/live_stream/upload_live_stream_video",
          {
            method: "POST",
            headers: {
              "x-sh-auth": token,
            },
            body: formData,
          }
        );

        if (response.ok) {
          const result = await response.json();
          console.log("Chunk uploaded successfully:", result);
        } else {
          console.error("Failed to upload chunk:", response.statusText);
        }
      } catch (error) {
        console.error("Chunk upload error:", error);
      }

      setChunkIndex((prev) => prev + 1);
    } else {
      console.error("Error: getBlob() did not return a Blob object.");
    }
  };

  const uploadVideo = async () => {
    const blob = recorder.getBlob();
    if (blob instanceof Blob) {
      const formData = new FormData();
      formData.append("file", blob, "recorded-video.webm");
      formData.append("module_id", "66f509f90fc4052120106b1c");
      formData.append("is_final_chunk", "true");

      const token = "your-auth-token"; // Replace with your token

      try {
        const response = await fetch(
          "http://192.168.1.220:4615/api/live_stream/upload_live_stream_video",
          {
            method: "POST",
            headers: {
              "x-sh-auth": token,
            },
            body: formData,
          }
        );

        if (response.ok) {
          const result = await response.json();
          alert("Video uploaded successfully");
          console.log("Server response:", result);
        } else {
          alert("Failed to upload video");
          console.error("Error uploading:", response.statusText);
        }
      } catch (error) {
        console.error("Upload error:", error);
      }
    } else {
      console.error("Error: getBlob() did not return a Blob object.");
    }
  };

  return (
    <div>
      <video ref={videoRef} autoPlay></video>
      <button onClick={startRecording} disabled={isRecording}>
        Start Recording
      </button>
      <button onClick={stopRecording} disabled={!isRecording}>
        Stop Recording
      </button>
      <button onClick={uploadVideo} disabled={!isUploadEnabled}>
        Upload Video
      </button>
    </div>
  );
};

export default HtmlRecorder;
