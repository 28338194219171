import { Avatar } from "@mui/material";
import React from "react";
import CustomMUITable from "src/components/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";

function MissionReward({ missionDetail, missionLevel }) {
  let TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "icon",
      label: "Icon",
      alignRight: false,
      renderData: (row) => {
        return <Avatar src={s3baseUrl + row.icon.thumbnail_1} alt="" />;
      },
    },
    { id: "title", label: "Title", alignRight: false },
    { id: "no_of_badges", label: "No. of Badges", alignRight: false },
  ];
  return (
    <>
      {/* <div>
        {missionDetail.mission_road_map_level &&
          missionDetail.mission_road_map_level.badges &&
          missionDetail.mission_road_map_level.badges.length > 0 && (
            <div className="mt-1 card p-2">
              <h3
                className="unlock-color mb-3 ms-2  mt-2 challenge-heading"
                style={{ fontWeight: 500 }}
              >
                {missionLevel.achieveable_badge_text}
              </h3>

              <div className="challenge-video-card">
                <div className="level-icons mb-2 ms-2 d-flex flex-wrap">
                  {missionDetail.mission_road_map_level &&
                  missionDetail.mission_road_map_level.badges &&
                  missionDetail.mission_road_map_level.badges.length > 0
                    ? missionDetail.mission_road_map_level.badges.map(
                        (item, index) => {
                          return (
                            <>
                              <div className="main-container-badges mb-2  me-2 p-2">
                                <div className="d-flex align-items-end">
                                  <img
                                    src={s3baseUrl + item.icon.thumbnail_2}
                                    alt=""
                                  />
                                  <p className="mb-0">{item.title}</p>
                                </div>
                                <span
                                  className="text-muted"
                                  style={{ color: "#7c7c7c" }}
                                >
                                  {item.no_of_badges + "x"}
                                </span>
                              </div>
                            </>
                          );
                        }
                      )
                    : ""}
                </div>
              </div>
            </div>
          )}
      </div> */}
      <div className="my-3">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={
            missionDetail.mission_road_map_level &&
            missionDetail.mission_road_map_level.badges &&
            missionDetail.mission_road_map_level.badges
          }
          className="card-with-background"
          hide_footer_pagination={true}
          hide_search={true}
          hide_footer={true}
          // title={missionLevel.achieveable_badge_text}
          removeOverFlow={true}
        />
      </div>
    </>
  );
}

export default MissionReward;
