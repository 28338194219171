import React, { useState, useEffect } from "react";
import { IconButton, CircularProgress, Chip, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { PodsDetailApi } from "src/DAL/Pods/Pods";
import { s3baseUrl } from "src/config/config";
import ReactTooltip from "react-tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { htmlDecode } from "src/utils/convertHtml";
import moment from "moment";
import { get_date_with_user_time_zone } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { ReactVideoPlayer } from "src/components";
import { isFileExistsOnS3 } from "src/utils/file_utils";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const PodsDetail = () => {
  const { userInfo, adminTimeZone } = useContentSetting();
  const navigate = useNavigate();
  const params = useParams();
  const [podsData, setPodsData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [copied, setCopied] = useState(false);
  const [isFileExist, setIsFileExist] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const getMemoryDetail = async () => {
    const result = await PodsDetailApi(params.pod_slug);
    if (result.code === 200) {
      setPodsData(result.room);
      if (result.room.stream_recording_url) {
        const is_file = await isFileExistsOnS3(
          result.room.stream_recording_url
        );
        setIsFileExist(is_file);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/pods`);
    }
  };
  const setCopiedPassword = () => {
    enqueueSnackbar("Password Copied To Clipboard", { variant: "success" });
    setCopied(true);
  };
  useEffect(() => {
    getMemoryDetail();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <h2 className="d-inline ms-2">{htmlDecode(podsData.title)}</h2>
        </div>
      </div>

      <div className="row section-space"></div>
      <div className="row">
        <div className="col-12 col-md-8">
          {podsData.stream_recording_url && isFileExist ? (
            <ReactVideoPlayer
              url={s3baseUrl + podsData.stream_recording_url}
              thumbnail={s3baseUrl + podsData.room_image.thumbnail_1}
              playingVideo={true}
            />
          ) : (
            <img
              width="100%"
              src={s3baseUrl + podsData.room_image.thumbnail_1}
            />
          )}
        </div>
        <div className="col-12 col-md-4">
          {podsData.start_date && (
            <p>
              Start Time :&nbsp;
              {get_date_with_user_time_zone(
                moment(podsData.start_date).format("YYYY-MM-DD") +
                  " " +
                  podsData.start_time,
                "hh:mm A",
                userInfo.time_zone,
                adminTimeZone
              )}
            </p>
          )}
          <p className="mt-3">{htmlDecode(podsData.short_description)}</p>

          <div className="row">
            <div className="col-12 col-md-6">
              {podsData.zoom_link && (
                <a
                  href={podsData.zoom_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="small-contained-button">
                    Join Meeting
                  </button>
                </a>
              )}
            </div>
            <div className="col-12 col-md-6 zoom-password">
              {podsData.password && (
                <CopyToClipboard
                  text={podsData.password}
                  onCopy={() => setCopiedPassword(true)}
                >
                  <Tooltip title="Click to copy password">
                    <Chip
                      label={
                        <>
                          {podsData.password}
                          <LockIcon />
                        </>
                      }
                      color="primary"
                      className="float-end me-1"
                      variant="outlined"
                    />
                  </Tooltip>
                </CopyToClipboard>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 section-space">
          <div
            dangerouslySetInnerHTML={{
              __html: podsData.detail_description,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default PodsDetail;
