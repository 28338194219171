import React from "react";
import { mediumGratitudeIcon } from "src/assets";
import {
  number_with_comma,
  replacePlaceholders,
  show_proper_words,
} from "src/utils/constants";

export default function StartMission(props) {
  const { missionDetail, handleCross, handleUnlock, isSubmitting } = props;
  let { content_settings, coins_to_unlock, user_data } = missionDetail;

  let coinsUnlock = number_with_comma(coins_to_unlock);
  let coinsCount = number_with_comma(user_data?.coins_count);

  let user_level = show_proper_words(user_data?.community_level);
  let user_coin = `<span style="display: inline-flex; align-items: center;">${coinsCount} <img src="${mediumGratitudeIcon}" alt="Coins" class="coins-icon" style="width: 16px; height: 16px; margin-left: 4px;" /></span>`;
  let mission_coin = `<span style="display: inline-flex; align-items: center;">${coinsUnlock} <img src="${mediumGratitudeIcon}" alt="Coins" class="coins-icon" style="width: 16px; height: 16px; margin-left: 4px;" /></span>`;

  const detail_Description = content_settings?.start_mission_description
    ? replacePlaceholders(content_settings?.start_mission_description, {
        community_level: user_level,
        coins_count: user_coin,
      })
    : "";

  const button_Description = content_settings?.start_mission_button_text
    ? replacePlaceholders(content_settings?.start_mission_button_text, {
        unlock_coins: mission_coin,
      })
    : "";

  const replacedString = (string, unlock_coins) => {
    if (!string) return;
    return string.replace(/{unlock_coins}/g, unlock_coins);
  };

  return (
    <div className="unlock-video p-2">
      <div className="unlock-popup-cross-icon" onClick={handleCross}>
        x
      </div>
      {content_settings?.start_mission_description && (
        <div
          dangerouslySetInnerHTML={{
            __html: replacedString(detail_Description, coins_to_unlock),
          }}
          className="pe-3"
        ></div>
      )}
      <button
        className="mc-button-contained w-100 mt-3"
        onClick={() => {
          handleUnlock(coins_to_unlock, user_data?.coins_count);
        }}
      >
        {isSubmitting ? (
          "Submitting..."
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: button_Description || "Start Mission",
            }}
          />
        )}
      </button>
      <button className="mc-button-outlined w-100 mt-3" onClick={handleCross}>
        {content_settings?.cancel_button_text || "Not Now"}
      </button>
    </div>
  );
}
