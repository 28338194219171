import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { memoriesListing, DeleteMomentdeApi } from "src/DAL/Memories/Memories";
import { useSnackbar } from "notistack";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import AddOrUpdateMemories from "src/components/Memories/AddOrUpdateMemories";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import MemoriesCard from "src/components/GeneralComponents/MemoriesCard";
import { s3baseUrl } from "src/config/config";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { tr } from "faker/lib/locales";
import RecordNotFound from "../../components/GeneralComponents/RecordNotFound";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 280,
  height: 55,
  color: "#fff",
  border: "1px solid #f6bd4b",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    boxShadow: theme.customShadows.z8,
    border: "2px solid #f6bd4b",
    width: 280,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

function Memories() {
  const observer = useRef();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [memoriesData, setMemoriesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [drawerState, setDrawerState] = useState(false);
  const [memoryslug, setMemorySlug] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [formType, setFormType] = useState("ADD");
  const [sortBy, setSortBy] = useState("newest_first");
  const [searchText, setSearchText] = useState("");
  const [loadMorePath, setLoadMorePath] = useState("");
  const load_more_path =
    "api/moment/get_magic_memories_with/filter?page=0&limit=10";

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
    setFormType("ADD");
  };

  const getMemoriesListing = async (path, search, loading, sort) => {
    if (!loading) {
      setIsLoading(true);
      path = load_more_path;
    }
    let postData = {
      search_text: search,
      sort_by: sort ? sort : sortBy,
    };

    const result = await memoriesListing(path, postData);
    if (result.code === 200) {
      let memoryArray = [];
      result.magicMoment.map((memory, index) => {
        memoryArray.push({
          ...memory,
          card_title: memory.moment_title,
          card_short_description: memory.moment_description,
          card_image: s3baseUrl + memory.moment_image[0].thumbnail_2,
          card_classes: "col-lg-4 col-md-6 col-sm-12 mt-4",
          pinteres_id: memory._id,
          pinterestImage: memory.moment_image[0].thumbnail_2,
          memoriesImages: memory.moment_image,
          pinterestDescription: memory.moment_description,
          pinterestTitle: memory.moment_title,
          pinterestDate: memory.moment_date,
          pinterestSlug: memory.moment_slug,
        });
      });

      if (loading) {
        let newArray = memoriesData.concat(memoryArray);
        setMemoriesData(newArray);
        setPageNumber((prev) => prev + 1);
      } else {
        setMemoriesData(memoryArray);
        setPageNumber(1);
      }
      setTotalPages(result.total_pages);
      setLoadMorePath(result.load_more);
      setIsLoading(false);
      setIsLoadingMore(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  const handleEdit = (value) => {
    setEditValues(value);
    setDrawerState(true);
    setFormType("EDIT");
  };

  const handleAgreeDelete = (value) => {
    setMemorySlug(value.pinterestSlug);
    setOpenDelete(true);
  };

  const handleSearchText = (event) => {
    setSearchText(event.target.value);
    localStorage.setItem("search_text_memories", event.target.value);
  };

  //Deleting Note
  const handleDelete = async () => {
    setOpenDelete(false);
    let myArray = [];
    const result = await DeleteMomentdeApi(memoryslug);
    if (result.code === 200) {
      myArray = memoriesData.filter(function (obj) {
        return obj.pinterestSlug !== memoryslug;
      });
      setMemoriesData(myArray);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const loadMoreData = () => {
    setIsLoadingMore(true);
    getMemoriesListing(loadMorePath, searchText, true);
  };

  const handleSortBy = (e) => {
    setSortBy(e.target.value);
    localStorage.setItem("sort_data", e.target.value);
    getMemoriesListing(loadMorePath, searchText, false, e.target.value);
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalPages >= pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totalPages, pageNumber, loadMorePath, observer]
  );

  useEffect(() => {
    let find_search = localStorage.getItem("search_text_memories");
    let find_sort = localStorage.getItem("sort_data");
    if (find_search) {
      setSearchText(find_search);
    }
    if (find_sort) {
      setSortBy(find_sort);
    }
    getMemoriesListing(load_more_path, find_search, false, find_sort);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const timeoutId = setTimeout(() => {
        getMemoriesListing(loadMorePath, searchText);
      }, 300);
      return () => clearTimeout(timeoutId);
    }
  }, [searchText]);

  const handleDetailPage = (value) => {
    navigate(`/memories/memories_detail/${value?.pinterestSlug}`);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div>
      <div className="container">
        <PageDescription
          parameter="memories_description"
          else_title="Memories"
        />
        <div className="row">
          {/* <div className="col-12">
            <button
              className="small-contained-button float-end ms-3 mt-1 mb-4"
              onClick={handleOpenDrawer}
              style={{
                marginRight: "14px",
              }}
            >
              Add Memories
            </button>
          </div> */}
          <div className="col-8 d-flex justify-content-end ms-auto mb-3">
            <SearchStyle
              value={searchText}
              onChange={handleSearchText}
              placeholder="Search"
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: "text.disabled" }}
                  />
                </InputAdornment>
              }
            />
            <div className="mx-3">
              <FormControl sx={{ minWidth: 150 }}>
                <InputLabel id="demo-select-small-label">Sort By</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  label="Sort By"
                  value={sortBy}
                  onChange={handleSortBy}
                >
                  <MenuItem value="newest_first">Newest First</MenuItem>
                  <MenuItem value="oldest_first">Oldest First</MenuItem>
                </Select>
              </FormControl>
            </div>
            <Button onClick={handleOpenDrawer} variant="outlined">
              Add Memories
            </Button>
          </div>
        </div>
        <div className="row">
          {memoriesData.length > 0 ? (
            <MemoriesCard
              data={memoriesData}
              MENU_OPTIONS={MENU_OPTIONS}
              handleClick={handleDetailPage}
            />
          ) : (
            <div className="mt-4">
              <RecordNotFound title="Data Not Found" />
            </div>
          )}
          {/* <PinterestLayout
            curserPointer={true}
            handleDetailPage={handleDetailPage}
            data={memoriesData}
            notFoundtitle="Memories Not Found"
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Are you sure you want to delete this Moment?"}
            handleAgree={handleDelete}
            menuOptions={MENU_OPTIONS}
          /> */}
          {totalPages >= pageNumber ? (
            <div className="col-12 text-center">
              <button
                ref={lastBookElementRef}
                className="small-contained-button mt-3"
                onClick={loadMoreData}
                disabled={isLoadingMore}
              >
                {isLoadingMore ? "Loading..." : "Load More"}
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${formType === "ADD" ? "Add" : "Edit"} Moment`}
        componentToPassDown={
          <AddOrUpdateMemories
            reloadListing={memoriesData}
            formType={formType}
            editData={editValues}
            setEditValues={setEditValues}
            onCloseDrawer={handleCloseDrawer}
            setMemoriesData={setMemoriesData}
          />
        }
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Moment?"}
        handleAgree={handleDelete}
      />
    </div>
  );
}

export default Memories;
