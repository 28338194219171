import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import Modal from "@mui/material/Modal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Avatar, InputBase } from "@mui/material";
import { add_new_feed, add_new_feed_v1 } from "src/DAL/Community/Community";
import { projectMode, s3baseUrl } from "src/config/config";
import { string_avatar } from "src/utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { update_feed_image_on_s3 } from "src/DAL/Login/Login";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import GeneralMenuPopup from "src/components/GeneralComponents/EmojiPickerPopup";
import { useRef } from "react";
import { htmlDecode } from "src/utils/convertHtml";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  maxWidth: "100%",
  maxHeight: "100vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
  overflow: "auto",
};

export default function CreatePostMission(props) {
  const { created_for_id, mission__id } = useParams();
  const { openCreateModal, setOpenCreateModal, rewardData } = props;
  const navigate = useNavigate();
  const { userInfo } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const [previews, setPreviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const [inputs, setInputs] = useState({
    feedAppearBy: "public",
    VideoUrl: "",
    description: "",
    LiveUrl: "",
  });
  console.log(rewardData.show_gif_image_on_reward_feed, "rewardData");
  const handleRemoveImage = (path) => {
    setPreviews((images) => {
      return images.filter((image) => image !== path);
    });
  };

  const UploadImages = async (formData) => {
    const result = await update_feed_image_on_s3(formData);
    return result.images_obj;
  };

  const handleUpload = async (event) => {
    let setImageObject = {};
    const fileList = event.target.files;
    const images_array = [];
    for (let i = 0; i < fileList.length; i++) {
      setImageObject = {
        path: fileList[i],
        type: "file",
      };
      images_array.push(setImageObject);
    }
    setPreviews((prevFiles) => [...prevFiles, ...images_array]);
  };

  const handleClose = () => {
    setOpenCreateModal(false);
    setInputs({ description: "" });
    setPreviews([]);
  };

  const handleCreatePost = async (e) => {
    e.preventDefault();
    if (!inputs.description && previews.length < 1) {
      enqueueSnackbar("Please add data to be posted", { variant: "error" });
      return;
    }

    setIsLoading(true);
    const results = previews.map((image, index) => {
      if (image.type === "file") {
        const formData = new FormData();
        formData.append("image", image.path);
        formData.append("width", 300);
        const result = UploadImages(formData);
        return result;
      } else {
        return image.path;
      }
    });

    Promise.all(results).then(async (img_results) => {
      let reward_data = {
        reward_feed_gif: rewardData?.reward_feed_image,
      };
      const formData = new FormData();
      formData.append("feed_appear_by", "public");
      formData.append("feed_type", "image");
      formData.append("video_url", inputs.VideoUrl);
      formData.append("description", inputs.description);
      formData.append("embed_code", inputs.LiveUrl);
      formData.append("feed_created_for", "mission");
      formData.append(
        "mission_id",
        created_for_id ? created_for_id : mission__id
      );
      if(rewardData.show_gif_image_on_reward_feed){
        formData.append("is_reward_feed", true);
      }else{
        formData.append("is_reward_feed", false);
      }
      formData.append("community_level", userInfo.community_level);
      if (rewardData.show_gif_image_on_reward_feed) {
        formData.append("reward_data", JSON.stringify(reward_data));
      }
      if (img_results) {
        formData.append("feed_images", JSON.stringify(img_results));
      }

      const result =
        projectMode === "DEV"
          ? await add_new_feed(formData)
          : await add_new_feed_v1(formData);
      if (result.code === 200) {
        setInputs({ VideoUrl: "", description: "" });
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "success" });
        localStorage.setItem("tabValuesMission", 2);
        navigate(
          `/mission-levels/level-details/${
            created_for_id ? created_for_id : mission__id
          }`
        );

        handleClose();
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    });
  };

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  return (
    <div>
      <Modal
        open={openCreateModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="create-post-title-form" onSubmit={handleCreatePost}>
          <Box sx={style} className="modal-theme">
            <div className="text-center modalIcon clo-12 create-post-title-box">
              <h2>Create Post</h2>

              <hr />
              <span onClick={() => handleClose()}>x</span>
            </div>
            <div className="col-12">
              <div className="d-flex justify-content-between">
                <div className="post-creator align-items-center">
                  <Avatar
                    src={s3baseUrl + userInfo?.profile_image}
                    alt="photoURL"
                  >
                    {string_avatar(
                      userInfo?.last_name
                        ? userInfo?.first_name + " " + userInfo.last_name
                        : userInfo?.first_name
                    )}
                  </Avatar>
                  <div className="creator-name ps-2 ">
                    <h3>{userInfo?.first_name + " " + userInfo?.last_name}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-3 position-relative">
              <InputBase
                inputRef={inputRef}
                style={{
                  color: "white",
                  width: "100%",
                  whiteSpace: "wrap",
                  paddingRight: "45px",
                }}
                sx={{ ml: 1, flex: 1 }}
                placeholder="What's on your mind?"
                inputProps={{
                  "aria-label": "What's on your mind?",
                }}
                multiline
                minRows="2"
                maxRows="6"
                name="description"
                value={inputs.description}
                onChange={handleChange}
                autoFocus
              />
              <GeneralMenuPopup
                use_field="description"
                setInput={setInputs}
                inputRef={inputRef}
              />
            </div>
            <div className="col-md-12 mt-2">
              <div className="row">
                {previews.length > 0 &&
                  previews.map((preview) => {
                    return (
                      <span className="col-3 mb-3 preview create-post-images">
                        <span
                          onClick={() => {
                            handleRemoveImage(preview);
                          }}
                        >
                          x
                        </span>
                        <img
                          src={
                            preview.type == "file"
                              ? URL.createObjectURL(preview.path)
                              : s3baseUrl + preview.thumbnail_1
                          }
                        />
                      </span>
                    );
                  })}
              </div>
              {/* {previews.length < 1 && ( */}

              <div className="row">
                <div className={`col-12`}>
                  <span className="upload-button mt-2 w-100">
                    <input
                      multiple
                      color="primary"
                      accept="image/*"
                      type="file"
                      id="icon-button-file"
                      style={{ display: "none" }}
                      onChange={handleUpload}
                    />
                    <label htmlFor="icon-button-file">
                      <p className="mt-3">
                        Add Photo <br />
                        <CloudUploadIcon />
                      </p>
                    </label>
                  </span>
                </div>
              </div>

              {/* )} */}
            </div>

            <div className="col-12 text-center mt-4">
              <button
                className="small-contained-button post-submit-btn"
                disabled={isLoading}
              >
                {isLoading
                  ? "Posting..."
                  : rewardData?.create_post_submit_button_text
                  ? rewardData?.create_post_submit_button_text
                  : "Post"}
              </button>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
}
