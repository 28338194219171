import React, { useEffect, useState } from "react";
import _ from "lodash";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LeftPart from "./LeftPart";
import RightPart from "./RightPart";
import MainPart from "./MainPart";
import CommunitySlider from "./CommunitySlider";
import { useSnackbar } from "notistack";
import { get_community_content } from "src/DAL/Community/Community";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import MobilesTabs from "./MobilesTabs";
import CommunityEventsSlider from "./CommunityEventsSlider";
import { useNavigate } from "react-router-dom";
import { mindflix_icon, profilePlaceholder } from "src/assets";
import { Icon } from "@iconify/react";
import RewardsList from "./MobileComponents/RewardsList";
import { htmlDecode } from "src/utils/convertHtml";
import moment from "moment";
import { ReactVideoPlayer } from "src/components";
import { COMMUNITY_LEVELS } from "src/utils/constants";
import { s3baseUrl } from "src/config/config";
import FeatureVideo from "./components/FeatureVideo";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function Community({
  feeds_type,
  event_id,
  event_category,
  current_events_array,
  upcoming_events_array,
  event_slug,
  eventDetail,
  streakLeaderBoard,
  setStreakLeaderBoard,
  loadMorePath,
  setLoadMorePath,
  loadMorePathCoin,
  setLoadMorePathCoin,
  coinLeaderBoard,
  setCoinLeaderBoard,
  communityMission,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const { roadMapLevels, contentSettingData, userInfo, feedSetting } =
    useContentSetting();
  const [leftSidebatTop, setLeftSidebatTop] = useState(0);
  const [rightSidebatTop, setRightSidebatTop] = useState(0);
  const [leaderboardData, setLeaderboardData] = useState({});
  const [showInfoBox, setShowInfoBox] = useState(false);
  const [rewardList, setRewardList] = useState([]);
  const [featureVideo, setFeatureVideo] = useState({});
  const [seriesVideos, setSeriesVideos] = useState([]);
  const [winFeedsList, setWinFeedsList] = useState([]);
  const [livePods, setLivePods] = useState([]);
  const [startingSoonPods, setStartingSoonPods] = useState([]);
  const [currenEvents, setCurrenEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [maximumLikeCount, setMaximumLikeCount] = useState(0);
  const [eliteLeaderboard, setEliteLeaderboard] = useState({});
  // const [feedSetting, setFeedSetting] = useState(feedSetting);
  const [dynamiteLeaderboard, setDynamiteLeaderboard] = useState({});
  const [liveLeaderboard, setLiveLeaderboard] = useState({});
  const [bonusCount, setBonusCount] = useState(0);
  const [clientDashboardSetting, setClientDashboardSetting] = useState({});
  const [isDisabledLeft, setIsDisabledLeft] = useState(false);
  const [isDisabledRight, setIsDisabledRight] = useState(false);
  const [descriptions, setDescriptions] = useState({});
  const [scrollValue, setScrollValue] = useState(0);

  let default_level_set = "all";
  let user_type = userInfo.community_level;
  let default_level = roadMapLevels.find((level) => level.is_default == true);
  if (default_level) {
    default_level_set = default_level.alias_title;
    user_type = default_level.alias_title;
  }
  const [userRoadmap, setuserRoadmap] = useState(default_level_set);

  const getCommunityContent = async () => {
    const result = await get_community_content();
    if (result.code === 200) {
      let reward_list = [];
      COMMUNITY_LEVELS.map((level) => {
        let reward_name = `${level.name}`;
        reward_list.push({
          level_name: reward_name,
          reward_list: result[`${level.name}_reward_list`],
        });
      });

      setRewardList(reward_list);
      setSeriesVideos(result.series_videos);
      setFeatureVideo(result.feature_video);
      // setFeedSetting(result.feed_setting);
      setCurrenEvents(result.current_events_array);
      setUpcomingEvents(result.upcoming_events_array);
      setLivePods(result.live_pods);
      setStartingSoonPods(result.starting_soon);
      setClientDashboardSetting(result.client_dashboard_setting);
      setWinFeedsList(result.win_feed);
      setMaximumLikeCount(result.maximum_like_count);
      setDynamiteLeaderboard(result.dynamite_leaderboard);
      setLiveLeaderboard(result.live_leaderboard);
      setEliteLeaderboard(result.elite_leaderboard);
      setBonusCount(result.bonus_count);
      setDescriptions({
        stick_description: result.stick_description,
        like_description: result.like_description,
        gratitude_description: result.gratitude_description,
        coin_description: result.coin_description,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeUserType = (event) => {
    setuserRoadmap(event.target.value);
  };

  const handleFavouriteVideosPage = () => {
    navigate(`/series-video/favourite-videos`);
  };

  const handleClickAll = () => {
    navigate(`/leader-boards`);
  };

  const handleChangePage = (id) => {
    navigate(`/series-video/${id}`);
  };

  const handleSlideLeft = () => {
    if (document.getElementsByClassName("hz-scroll").length > 0) {
      const scroll = -window.innerWidth * 0.8;
      document.getElementsByClassName("hz-scroll")[0].scrollBy({
        left: scroll,
        behavior: "smooth",
      });
      setScrollValue(scroll);
    }
  };

  const handleSlideRight = () => {
    if (document.getElementsByClassName("hz-scroll").length > 0) {
      const scroll = window.innerWidth * 0.8;
      document.getElementsByClassName("hz-scroll")[0].scrollBy({
        left: scroll,
        behavior: "smooth",
      });
      setScrollValue(scroll);
    }
  };

  useEffect(() => {
    if (feeds_type == "general") {
      getCommunityContent();
    } else {
      setIsLoading(false);
    }
  }, []);

  let get_height = (id) => {
    if (!isLoading) {
      setTimeout(() => {
        var rightCol = document.getElementById(id);
        if (rightCol) {
          var topPosition = window.innerHeight - rightCol.offsetHeight - 120;
          if (topPosition > 0) {
            topPosition = 80;
          }
          rightCol.style.top = topPosition + "px";
        }
      }, 0);
    }
  };

  const updateSidebarPosition = () => {
    get_height("sidebar-left");
    get_height("sidebar-right");
  };

  useEffect(() => {
    updateSidebarPosition();
    window.addEventListener("scroll", updateSidebarPosition);
  }, [isLoading]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="new-community-page">
      {feeds_type === "general" && (
        <>
          <section className="banner-section">
            <div className="container position-relative">
              <img
                src={s3baseUrl + clientDashboardSetting.cover_image_url}
                alt=""
                className="img-fluid banner-bg-image"
              />
              <div className="jade-div">
                <img
                  src={
                    userInfo.profile_image
                      ? s3baseUrl + userInfo.profile_image
                      : profilePlaceholder
                  }
                  alt=""
                  className="jade-img"
                />
              </div>
            </div>
          </section>
          <div className="mobile-case d-block d-lg-none">
            {window.screen.width < 911 && (
              <>
                {/* <div className="row slider community-events-slider pt-4">
                  <div className="col-12 bg-img">
                    <CommunityEventsSlider
                      data={[...currenEvents, ...upcomingEvents]}
                    />
                  </div>
                </div> */}
                <MobilesTabs
                  showInfoBox={showInfoBox}
                  setShowInfoBox={setShowInfoBox}
                  descriptions={descriptions}
                  feeds_type={feeds_type}
                  event_id={event_id}
                  maximumLikeCount={maximumLikeCount}
                  bonusCount={bonusCount}
                  feedSetting={feedSetting}
                  handleSlideLeft={handleSlideLeft}
                  handleSlideRight={handleSlideRight}
                  clientDashboardSetting={clientDashboardSetting}
                  featureVideo={featureVideo}
                  handleChangePage={handleChangePage}
                  seriesVideos={seriesVideos}
                  upcomingEvents={upcomingEvents}
                  currenEvents={currenEvents}
                  winFeedsList={winFeedsList}
                  setIsDisabledLeft={setIsDisabledLeft}
                  setIsDisabledRight={setIsDisabledRight}
                  isDisabledLeft={isDisabledLeft}
                  isDisabledRight={isDisabledRight}
                  rewardList={rewardList}
                  userType={user_type}
                  handleChangeUserType={handleChangeUserType}
                  userRoadmap={userRoadmap}
                  handleFavouriteVideosPage={handleFavouriteVideosPage}
                  livePods={livePods}
                  startingSoonPods={startingSoonPods}
                />
              </>
            )}
          </div>
          <section className="membership-section text-center d-none d-lg-block">
            <div className="container">
              <div className="hz-scroll mt-5">
                <span className="hz-scroll-left" onClick={handleSlideLeft}>
                  <Icon className="hz-scroll-icon" icon="ep:arrow-left-bold" />
                </span>
                <span className="hz-scroll-right" onClick={handleSlideRight}>
                  <Icon className="hz-scroll-icon" icon="ep:arrow-right-bold" />
                </span>

                {rewardList.map((reward, index) => {
                  return (
                    <RewardsList
                      reward={reward}
                      index={index}
                      clientDashboardSetting={clientDashboardSetting}
                    />
                  );
                })}
              </div>
            </div>
          </section>
          {featureVideo && featureVideo.title && (
            // <section className="success-wrapper d-none d-lg-block">
            //   <div className="container">
            //     <div className="row">
            //       <div className="col-lg-6 pe-lg-5">
            //         <div className="row pb-4">
            //           <div className="col-8 col-lg-6">
            //             <img src={mindflix_icon}></img>
            //           </div>
            //         </div>
            //         <h1>{htmlDecode(featureVideo.title)}</h1>
            //         <h2>
            //           {featureVideo.series_category &&
            //             featureVideo.series_category.title && (
            //               <>
            //                 <span>{featureVideo.series_category.title}</span>{" "}
            //                 &nbsp;
            //               </>
            //             )}
            //           {moment(featureVideo.date).format("YYYY")} &nbsp;
            //           {htmlDecode(featureVideo.season_statement)}
            //         </h2>
            //         <p>{htmlDecode(featureVideo.short_description)}</p>
            //         <div className="btn-wrapper">
            //           <button
            //             className="play-btn me-2"
            //             onClick={() => {
            //               handleChangePage(featureVideo._id);
            //             }}
            //           >
            //             <i className="fa-solid fa-play" />
            //             &nbsp; Play
            //           </button>
            //         </div>
            //       </div>
            //       <div className="col-lg-6 mt-5 mt-lg-0">
            //         <ReactVideoPlayer url={featureVideo.trailer_video_url} />
            //       </div>
            //     </div>
            //   </div>
            // </section>
            <FeatureVideo
              featureVideo={featureVideo}
              handleChangePage={handleChangePage}
            />
          )}
          <section className="slider py-5 d-none d-lg-block">
            <div className="container bg-img">
              <CommunitySlider data={seriesVideos} />
              <div className="row"></div>
            </div>
          </section>
        </>
      )}
      <div
        className={`row ${feeds_type === "general" ? "d-none d-lg-flex" : ""}`}
      >
        <div className="col-md-12 col-lg-3 order-2 order-lg-1 pb-4">
          <div className="sticky-lg-top" id="sidebar-left">
            <LeftPart
              showInfoBox={showInfoBox}
              setShowInfoBox={setShowInfoBox}
              currenEvents={currenEvents}
              upcomingEvents={upcomingEvents}
              userInfo={userInfo}
              maximumLikeCount={maximumLikeCount}
              bonusCount={bonusCount}
              descriptions={descriptions}
              feedSetting={feedSetting}
              feeds_type={feeds_type}
              event_category={event_category}
              event_id={event_id}
              livePods={livePods}
              startingSoonPods={startingSoonPods}
              event_slug={event_slug}
              communityMission={communityMission}
            />
          </div>
        </div>
        <div className="col-md-12 col-lg-6 order-1 order-lg-2 community-main-section pb-4">
          <MainPart
            showInfoBox={showInfoBox}
            setShowInfoBox={setShowInfoBox}
            userInfo={userInfo}
            descriptions={descriptions}
            feeds_type={feeds_type}
            event_id={event_id}
            feedSetting={feedSetting}
            userType={user_type}
            userRoadmap={userRoadmap}
          />
        </div>
        <div className="col-md-12 col-lg-3 order-3 pb-4">
          <div className="sticky-lg-top" id="sidebar-right">
            <RightPart
              winFeedsList={winFeedsList}
              leaderboardData={leaderboardData}
              currenEvents={currenEvents}
              upcomingEvents={upcomingEvents}
              userInfo={userInfo}
              feeds_type={feeds_type}
              upcoming_events_array={upcoming_events_array}
              current_events_array={current_events_array}
              eventDetail={eventDetail}
              feedSetting={feedSetting}
              userType={user_type}
              userRoadmap={userRoadmap}
              handleChangeUserType={handleChangeUserType}
              handleFavouriteVideosPage={handleFavouriteVideosPage}
              handleClickAll={handleClickAll}
              streakLeaderBoard={streakLeaderBoard}
              setStreakLeaderBoard={setStreakLeaderBoard}
              loadMorePath={loadMorePath}
              setLoadMorePath={setLoadMorePath}
              coinLeaderBoard={coinLeaderBoard}
              setCoinLeaderBoard={setCoinLeaderBoard}
              loadMorePathCoin={loadMorePathCoin}
              setLoadMorePathCoin={loadMorePathCoin}
              communityMission={communityMission}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Community;
