import React, { useEffect, useRef } from "react";
import LiveStreamFooter from "./LiveStreamFooter";
import { s3baseUrl } from "src/config/config";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

const Background = ({ imageUrl }) => (
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: `url(${imageUrl})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      borderRadius: "10px",
      zIndex: 1,
    }}
  />
);

export default function VideoRemoteOutput({
  meetingSession,
  className,
  tileUsers,
  attendee_info,
  feedDetail,
  handleOpenSettingsDrawer,
}) {
  const videoRef = useRef(null);
  const { userInfo } = useContentSetting();

  useEffect(() => {
    const { attendee_id } = attendee_info;
    let exist = tileUsers.find((tile) => attendee_id === tile.attendee_id);
    if (exist && videoRef.current) {
      meetingSession.audioVideo.bindVideoElement(
        exist.tile_id,
        videoRef.current
      );
    }
  }, [attendee_info, meetingSession, videoRef, tileUsers]);

  // console.log(attendee_info, "attendee_infoattendee_info");

  return (
    <div className="co-host-video mb-1">
      {attendee_info.is_video_paused && (
        <Background imageUrl={s3baseUrl + feedDetail.image.thumbnail_1} />
      )}
      <div className="attendee-user-name">
        {attendee_info.first_name + " " + attendee_info.last_name}
      </div>
      <video ref={videoRef} autoPlay playsinline className={className || ""} />
      {userInfo._id === attendee_info._id && (
        <div className="live-stream-footer">
          <div className="stream-controls-wrapper py-1">
            <LiveStreamFooter
              meetingSession={meetingSession}
              feedDetail={feedDetail}
              isPaused={attendee_info.is_video_paused}
              isMuted={attendee_info.is_video_muted}
              attendee_id={attendee_info.attendee_id}
              handleOpenSettingsDrawer={handleOpenSettingsDrawer}
            />
          </div>
        </div>
      )}
    </div>
  );
}
