import React from "react";
import { last_section_img } from "src/assets";
import CurrentPods from "./CurrentPods";
import EventVideos from "./EventVideos";
import TwitterFeeds from "./TwitterFeeds";
import AboutSection from "./AboutSection";
import AnalyticsChart from "../MissionLevels/AnalyticsChart";
import AnalyticsChartHealth from "../MissionLevels/AnalyticsChartHealth";
import AnalyticsChartrecreational from "../MissionLevels/AnalyticsChartrecreational";
import { get_short_string } from "src/utils/constants";
import { Tooltip } from "@mui/material";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";

function LeftPart({
  showInfoBox,
  setShowInfoBox,
  upcomingEvents,
  currenEvents,
  userInfo,
  maximumLikeCount,
  bonusCount,
  descriptions,
  feedSetting,
  feeds_type,
  event_category,
  event_id,
  startingSoonPods,
  livePods,
  event_slug,
  communityMission,
}) {
  console.log(communityMission, "communityMissioncommunityMission");
  return (
    <div className="community-left-sidebar">
      {feeds_type === "general" ? (
        <>
          <AboutSection
            setShowInfoBox={setShowInfoBox}
            showInfoBox={showInfoBox}
            userInfo={userInfo}
            maximumLikeCount={maximumLikeCount}
            bonusCount={bonusCount}
            descriptions={descriptions}
            feedSetting={feedSetting}
          />
          <CurrentPods
            feeds_type={feeds_type}
            livePods={livePods}
            startingSoonPods={startingSoonPods}
          />
          <TwitterFeeds
            upcomingEvents={upcomingEvents}
            currenEvents={currenEvents}
            userInfo={userInfo}
            feeds_type={feeds_type}
          />
          <img className="mt-3" src={last_section_img}></img>
        </>
      ) : feeds_type === "mission" ? (
        <div className="hide-in-mobile">
          <div className="">
            <div className="mission-control-card  fix-height-issue p-3">
              <div className="d-flex justify-content-between members-leader-board">
                <h4 style={{ textAlign: "left" }} className="mb-3">
                  Continued Mission
                </h4>
              </div>

              {communityMission.user_continue_missions.length > 0 ? (
                communityMission.user_continue_missions.map((item, index) => {
                  return (
                    <>
                      <div className="mt-2 mb-2">
                        <div className="challenge-item">
                          <Tooltip title={item?.mission_info?.title}>
                            <div className="challenge-title">
                              {get_short_string(item?.mission_info?.title, 20)}
                            </div>
                          </Tooltip>
                          <Tooltip
                            title={
                              (
                                (item.completed_mission_days * 100) /
                                item.mission_duration
                              ).toFixed(2) + "%"
                            }
                          >
                            <div className="coins-slider mt-1">
                              <div
                                className="coins-slider-outer"
                                style={{
                                  height: "5px",
                                }}
                              >
                                <div
                                  className="coins-slider-inner"
                                  style={{
                                    width:
                                      (item.completed_mission_days * 100) /
                                        item.mission_duration +
                                      "%",
                                    maxWidth: "100%",
                                    cursor: "pointer",
                                  }}
                                ></div>
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <RecordNotFound title="Data not found" show_new_image={true} />
              )}
            </div>
          </div>
        </div>
      ) : (
        <EventVideos
          feeds_type={feeds_type}
          event_category={event_category}
          event_id={event_id}
          event_slug={event_slug}
        />
      )}
    </div>
  );
}

export default LeftPart;
