import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const ReactApexChartDashboard = ({ graphData }) => {
  let data = [
    {
      title: "Day 13 ",
      answer_statement: 8,
      date: "Nov 04",
      question_statement:
        "Today I rate my mood (or would it be better - Today I am feeling?)",
      question_id: "672871f245022fef7cae6e7c",
      question_type: "scaling",
      max_value: 10,
      min_value: 1,
    },
    {
      title: "Day 14 ",
      answer_statement: 6,
      date: "Nov 04",
      question_statement:
        "Today I rate my mood (or would it be better - Today I am feeling?)",
      question_id: "672871f245022fef7cae6e7c",
      question_type: "scaling",
      max_value: 10,
      min_value: 1,
    },
    {
      title: "Day 15 ",
      answer_statement: 7,
      date: "Nov 04",
      question_statement:
        "Today I rate my mood (or would it be better - Today I am feeling?)",
      question_id: "672871f245022fef7cae6e7c",
      question_type: "scaling",
      max_value: 10,
      min_value: 1,
    },
    {
      title: "Day 16 ",
      answer_statement: 8,
      date: "Nov 04",
      question_statement:
        "Today I rate my mood (or would it be better - Today I am feeling?)",
      question_id: "672871f245022fef7cae6e7c",
      question_type: "scaling",
      max_value: 10,
      min_value: 1,
    },
    {
      title: "Day 17 ",
      answer_statement: 8,
      date: "Nov 05",
      question_statement:
        "Today I rate my mood (or would it be better - Today I am feeling?)",
      question_id: "672871f245022fef7cae6e7c",
      question_type: "scaling",
      max_value: 10,
      min_value: 1,
    },
    {
      title: "Day 18 ",
      answer_statement: 9,
      date: "Nov 05",
      question_statement:
        "Today I rate my mood (or would it be better - Today I am feeling?)",
      question_id: "672871f245022fef7cae6e7c",
      question_type: "scaling",
      max_value: 10,
      min_value: 1,
    },
    {
      title: "Day 13 ",
      answer_statement: 7,
      date: "Nov 04",
      question_statement:
        "&nbsp;How committed am I feeling today to achieve greatness in all I do?",
      question_id: "6728720945022fef7cae6e8c",
      question_type: "scaling",
      max_value: 10,
      min_value: 1,
    },
    {
      title: "Day 14 ",
      answer_statement: 9,
      date: "Nov 04",
      question_statement:
        "&nbsp;How committed am I feeling today to achieve greatness in all I do?",
      question_id: "6728720945022fef7cae6e8c",
      question_type: "scaling",
      max_value: 10,
      min_value: 1,
    },
    {
      title: "Day 15 ",
      answer_statement: 10,
      date: "Nov 04",
      question_statement:
        "&nbsp;How committed am I feeling today to achieve greatness in all I do?",
      question_id: "6728720945022fef7cae6e8c",
      question_type: "scaling",
      max_value: 10,
      min_value: 1,
    },
    {
      title: "Day 16 ",
      answer_statement: 6,
      date: "Nov 04",
      question_statement:
        "&nbsp;How committed am I feeling today to achieve greatness in all I do?",
      question_id: "6728720945022fef7cae6e8c",
      question_type: "scaling",
      max_value: 10,
      min_value: 1,
    },
    {
      title: "Day 17 ",
      answer_statement: 8,
      date: "Nov 05",
      question_statement:
        "&nbsp;How committed am I feeling today to achieve greatness in all I do?",
      question_id: "6728720945022fef7cae6e8c",
      question_type: "scaling",
      max_value: 10,
      min_value: 1,
    },
    {
      title: "Day 18 ",
      answer_statement: 9,
      date: "Nov 05",
      question_statement:
        "&nbsp;How committed am I feeling today to achieve greatness in all I do?",
      question_id: "6728720945022fef7cae6e8c",
      question_type: "scaling",
      max_value: 10,
      min_value: 1,
    },
  ];

  const categorizeDataByDate = (data) => {
    const categorizedData = data.reduce((acc, item) => {
      const {
        date,
        question_statement,
        answer_statement,
        question_id,
        min_value,
        max_value,
        title,
      } = item;
      if (!acc[date]) {
        acc[date] = {
          date,
          questions: [],
        };
      }
      acc[date].questions.push({
        question_statement,
        answer_statement,
        question_id,
        min_value,
        max_value,
        title,
      });
      return acc;
    }, {});

    return Object.values(categorizedData);
  };

  const categorizedData = categorizeDataByDate(data);
  const series = categorizedData.map((item) => {
    return {
      name: item.date,
      data: item.questions.map((question) => question.answer_statement),
    };
  });
  console.log(series, "seriesseriesseriesseries");
  const categories = categorizedData.map((item) => item.date);
  const colors = categorizedData.map(
    () => `#${Math.floor(Math.random() * 16777215).toString(16)}`
  );

  const [chartData, setChartData] = useState(null);
  useEffect(() => {
    if (graphData && graphData.length > 0) {
      // const minValue = Math.min(...graphData.map((item) => item.min_value));
      // const maxValue = Math.max(...graphData.map((item) => item.max_value));

      setChartData({
        series: series,
        options: {
          chart: {
            type: "bar",
            height: 500,
          },
          // colors: colors,
          grid: {
            show: true,
            borderColor: "#e2e7e7",
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "20px",
              borderRadius: 2,
              borderRadiusApplication: "end",
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 5,
            colors: ["transparent"],
          },
          xaxis: {
            categories: categories,
            labels: {
              style: {
                colors: "white",
              },
            },
            axisBorder: {
              show: true,
              color: "white",
            },
          },
          yaxis: {
            // min: minValue,
            // max: maxValue,
            tickAmount: 4,
            labels: {
              style: {
                colors: "white",
              },
            },
          },
          fill: {
            opacity: 1,
          },
          legend: {
            show: false,
          },
          tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
            x: {
              show: true,
              format: "dd MMM",
            },
            y: {
              formatter: function (val) {
                return `${val}`;
              },
            },
            style: {
              fontSize: "12px",
              fontFamily: undefined,
            },
            fixed: {
              enabled: true,
              position: "topLeft",
              offsetX: 10,
              offsetY: 0,
            },
          },
        },
      });
    }
  }, [graphData]);

  if (!chartData) {
    return <div>Loading...</div>;
  }
  console.log(chartData, "chartData");

  return (
    <div className="">
      <div>
        <div id="chart" style={{ position: "relative" }}>
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={200}
            width={"100%"}
          />
        </div>
        <div id="html-dist"></div>
      </div>
    </div>
  );
};

export default ReactApexChartDashboard;
