import { useEffect, useState } from "react";
import { Outlet, Navigate, useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ReactGA from "react-ga";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { member_init_with_token_api } from "src/DAL/ContentSetting/ContentSetting";
import { useSnackbar } from "notistack";
import { CircularProgress, Drawer, Tooltip } from "@mui/material";
import RenderNavbar from "./RenderNavbar";
import sidebarConfig from "./SidebarConfig";
import { mobile_banner } from "src/assets";
import { s3baseUrl } from "src/config/config";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 65;
const DRAWER_WIDTH = 280;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  // overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  // overflow: "auto",
  width: screen.width > 991 ? `calc(100vw - 310px)` : "100vw",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  // [theme.breakpoints.down("md")]: {
  //   paddingTop: 130,
  // },
}));

// ----------------------------------------------------------------------
const handle_embed_tinymce_key = (key) => {
  var head = document.head;
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src = `https://cdn.tiny.cloud/1/${key}/tinymce/5/tinymce.min.js`;
  head.appendChild(script);
};
const handle_add_google_analytic_key = (key) => {
  var head = document.head;
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src = `https://www.googletagmanager.com/gtag/js?id=${key}`;
  head.appendChild(script);
};
const handle_append_pixel_code_header = (scriptContent) => {
  try {
    // Create a temporary container to hold the script content
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = scriptContent;

    // Loop through the child nodes of tempDiv and append them to the head
    Array.from(tempDiv.childNodes).forEach((node) => {
      if (node.nodeType === Node.COMMENT_NODE) {
        // If it's a comment node, append it as a comment
        const comment = document.createComment(node.nodeValue);
        document.head.appendChild(comment);
      } else if (
        node.nodeType === Node.ELEMENT_NODE &&
        node.tagName === "SCRIPT"
      ) {
        // If it's a script node, create a new script element
        const scriptTag = document.createElement("script");

        // Copy all attributes (like "async" and "src")
        Array.from(node.attributes).forEach((attr) =>
          scriptTag.setAttribute(attr.name, attr.value)
        );

        // If the script has inline JavaScript content, set it
        if (node.innerHTML) {
          scriptTag.innerHTML = node.innerHTML;
        }

        // Append the script to the head
        document.head.appendChild(scriptTag);
      }
    });
  } catch (error) {
    console.error("Failed to append pixel code:", error);
  }
};
const handle_append_pixel_code_body = (noscriptContent) => {
  try {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = noscriptContent;

    Array.from(tempDiv.childNodes)
      .reverse()
      .forEach((node) => {
        document.body.insertBefore(node, document.body.firstChild);
      });
  } catch (error) {
    console.error("Failed to append noscript content:", error);
  }
};

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showBannerImage, setShowBannerImage] = useState(true);
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [contentSetting, setContentSetting] = useState({});

  const location = useLocation();
  const navigate = useNavigate();

  function test(words) {
    var n = words.split("=");
    return n[n.length - 1];
  }
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }
  let mobileView = test(location.search);

  var pattern = /^\/live-events\/([^\/]+)$/;
  var match = location.pathname.match(pattern);

  const { enqueueSnackbar } = useSnackbar();
  const {
    handleContentSettingData,
    handleDashboardSettingData,
    handleAdminTimeZone,
    handleUserInfo,
    handleConsultantInfo,
    handleWheelOfLifeSetting,
    handleNavbarList,
    handleNotificationsCount,
    handleUnreadMessageCount,
    handleStripeKey,
    handleAffiliateUrlName,
    handleJSCode,
    handleCSSCode,
    handleFeedSetting,
    handleCommunityLevels,
    setRoadMapLevels,
    setCardElement,
    handleNotifications,
    handleGoogleId,
    navbarList,
    userInfo,
    setSiteSetting,
    setBankCurrencies,
    setShopContentSettings,
    setStripeKeysObject,
    setStripeKeyForShop,
    setLoginInfo,
    tinymceKey,
    settinymceKey,
  } = useContentSetting();

  const getContentSetting = async () => {
    const result = await member_init_with_token_api();
    let StripeKeysObject = {
      live_publish_key: result.site_setting.live_publish_key,
      sandBox_publish_key: result.site_setting.sandBox_publish_key,
    };

    if (result.code === 200) {
      settinymceKey(result.tinymce_key);
      handle_embed_tinymce_key(result.tinymce_key);
      setSiteSetting(result.site_setting);
      if (result.content_setting.client_general_setting.pixel_code_header) {
        handle_append_pixel_code_header(
          result.content_setting.client_general_setting.pixel_code_header
        );
      }
      if (result.content_setting.client_general_setting.pixel_code_body) {
        handle_append_pixel_code_body(
          result.content_setting.client_general_setting.pixel_code_body
        );
      }
      if (result.content_setting.client_general_setting.analytic_google_code) {
        handle_add_google_analytic_key(
          result.content_setting.client_general_setting.analytic_google_code
        );
      }
      setContentSetting(result.content_setting.client_content_setting);
      let notification_settings = result.member.notification_settings;
      if (result.content_setting === null) {
        handleDashboardSettingData({});
        handleAffiliateUrlName("");
        handleContentSettingData({});
        handleNavbarList({});
        handleWheelOfLifeSetting({});
        handleUserInfo({});
        handleConsultantInfo({});
        handleAdminTimeZone("");
        handleNotificationsCount(0);
        handleUnreadMessageCount(0);
        handleCommunityLevels([]);
        setRoadMapLevels([]);
        setBankCurrencies([]);
        handleNotifications({});
        setShopContentSettings({});
        handleFeedSetting(null);
        return;
      }
      localStorage.setItem(
        `content_setting`,
        JSON.stringify(result.content_setting.client_content_setting)
      );
      localStorage.setItem(
        `client_dashboard_setting`,
        JSON.stringify(result.content_setting.client_dashboard_setting)
      );
      localStorage.setItem(`admin_time_zone`, JSON.stringify(result.time_zone));
      localStorage.setItem(
        `wheel_of_life_setting`,
        JSON.stringify(result.content_setting.wheel_of_life_setting)
      );
      localStorage.setItem(`user_data`, JSON.stringify(result.member));
      localStorage.setItem(`navbar_list`, JSON.stringify(result.menus));
      localStorage.setItem(
        `jsCode`,
        JSON.stringify(result.client_portal_js_code)
      );
      localStorage.setItem(
        `cssCode`,
        JSON.stringify(result.client_portal_css_code)
      );
      localStorage.setItem(
        `consultant_data`,
        JSON.stringify(result.member_consultant)
      );
      setLoginInfo(result);
      handleAffiliateUrlName(result.affiliate_url_name);
      handleAdminTimeZone(result.time_zone);
      handleNavbarList(result.menus);
      setBankCurrencies(result.currencies);
      handleContentSettingData(result.content_setting.client_content_setting);
      handleWheelOfLifeSetting(result.content_setting.wheel_of_life_setting);
      handleJSCode(result.client_portal_js_code);
      handleCSSCode(result.client_portal_css_code);
      handleFeedSetting(result.feed_setting);
      handleCommunityLevels(result.community_levels);
      setRoadMapLevels(result.member.road_map_levels);
      setShopContentSettings(result.storehouse_content_setting);
      handleDashboardSettingData(
        result.content_setting.client_dashboard_setting
      );
      handleNotificationsCount(result.unread_notification_count);
      handleUnreadMessageCount(result.unread_message_count);
      handleUserInfo(result.member);
      if (result.cart.cart_products) {
        setCardElement(result.cart.cart_products);
      }

      handleConsultantInfo(result.member_consultant);
      if (notification_settings) {
        handleNotifications(notification_settings);
      }
      // ReactGA.initialize(
      //   result.content_setting.client_general_setting.analytic_google_code,
      //   { debug: true }
      // );
      setStripeKeysObject(StripeKeysObject);
      if (result.site_setting.stripe_mode == "sandBox") {
        handleStripeKey(result.site_setting.sandBox_publish_key);
      } else {
        handleStripeKey(result.site_setting.live_publish_key);
      }
      if (result.site_setting.client_id) {
        handleGoogleId(result.site_setting.client_id);
      }
      if (
        result.storehouse_content_setting &&
        result.storehouse_content_setting.payment_mode == "sandBox"
      ) {
        setStripeKeyForShop(result.site_setting.sandBox_publish_key);
      } else {
        setStripeKeyForShop(result.site_setting.live_publish_key);
      }
      if (result.wheel_of_life) {
        navigate("/questions");
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  // ReactGA.pageview(location.pathname + location.search);

  const onCloseSidebar = () => {
    setOpen(false);
    setOpenSettings(false);
  };

  const handleOpenSidebar = () => {
    setOpen(true);
  };

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Smooth scrolling animation
    });
  };

  function handleScroll() {
    const isScrolled =
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop) > window.innerHeight;
    setShowScrollArrow(isScrolled);
  }

  useEffect(() => {
    getContentSetting();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setOpen(false);
  }, [location]);

  if (isLoading) {
    return <CircularProgress className="centered" color="primary" />;
  }

  let nav_items = navbarList.filter((item) => item.show_on === "main_sidebar");

  return (
    <RootStyle>
      {mobileView == "mobile" ? (
        ""
      ) : (
        <>
          <DashboardNavbar
            onOpenSidebar={handleOpenSidebar}
            onOpenSetting={() => setOpenSettings(true)}
          />

          {match ? (
            <>
              {open && (
                <Drawer
                  open={open}
                  onClose={onCloseSidebar}
                  PaperProps={{
                    sx: { width: DRAWER_WIDTH },
                    className: "sidebar-drawer",
                  }}
                >
                  <RenderNavbar navConfig={nav_items} />
                </Drawer>
              )}
            </>
          ) : (
            <DashboardSidebar
              isOpenSidebar={open}
              openSettings={openSettings}
              onCloseSidebar={onCloseSidebar}
            />
          )}
        </>
      )}

      <MainStyle>
        {contentSetting.show_mobile_app_banner &&
          screen.width < 576 && //only for mobile screen
          showBannerImage && (
            <div className="position-relative">
              <span
                className="mobile-banner-cross"
                onClick={() => setShowBannerImage(false)}
              >
                x
              </span>
              <a href={contentSetting.mobile_app_banner_link} target="_blank">
                {contentSetting.mobile_app_banner && (
                  <img
                    src={s3baseUrl + contentSetting.mobile_app_banner}
                    className="w-100 mb-5"
                  />
                )}
              </a>
            </div>
          )}
        <Outlet />
        <div
          className="scroll-top-community"
          style={{
            bottom: showScrollArrow ? "50px" : "-80px",
          }}
          onClick={handleScrollTop}
        >
          <Tooltip title="Scroll to top">
            <div>
              <i class="fa-solid fa-arrow-up"></i>
            </div>
          </Tooltip>
        </div>
      </MainStyle>
    </RootStyle>
  );
}
