import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { get_root_value } from "src/utils/domUtils";

function LiveStreamSettings(props) {
  const [cameraList, setCameraList] = useState([]);
  const [micList, setMicList] = useState([]);
  const {
    activeCam,
    setActiveCam,
    activeMic,
    setActiveMic,
    meetingSession,
    openSettings,
  } = props;

  // Function to switch camera in AWS Chime SDK
  const handleSwitchCamera = async (deviceId) => {
    if (meetingSession && meetingSession.audioVideo) {
      await meetingSession.audioVideo.startVideoInput(deviceId);
      setActiveCam(deviceId);
    }
  };

  // Function to switch microphone in AWS Chime SDK
  const handleSwitchMicrophone = async (deviceId) => {
    if (meetingSession && meetingSession.audioVideo) {
      await meetingSession.audioVideo.startAudioInput(deviceId);
      setActiveMic(deviceId);
    }
  };

  // Fetch available devices and set the active ones
  useEffect(() => {
    const fetchDevices = async () => {
      if (meetingSession && meetingSession.audioVideo) {
        const videoInputDevices =
          await meetingSession.audioVideo.listVideoInputDevices();
        const audioInputDevices =
          await meetingSession.audioVideo.listAudioInputDevices();

        setCameraList(videoInputDevices);
        setMicList(audioInputDevices);
      }
    };
    if (openSettings) {
      fetchDevices();
    }
  }, [openSettings]);

  return (
    <div className="row w-100 p-3">
      <div className="col-12 mt-2">
        <FormControl fullWidth size="small">
          <InputLabel shrink={true} id="cam-list">
            Camera Devices
          </InputLabel>
          <Select
            labelId="cam-list"
            id="camera-select"
            label="Camera Devices"
            onChange={(e) => handleSwitchCamera(e.target.value)}
            value={activeCam || ""}
            sx={{
              color: get_root_value("--input-text-color"),
            }}
          >
            {cameraList.map((cam) => (
              <MenuItem key={cam.deviceId} value={cam.deviceId}>
                {cam.label || "Unnamed Camera"}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="col-12 mt-3">
        <FormControl fullWidth size="small">
          <InputLabel shrink={true} id="mic-list">
            Microphone Devices
          </InputLabel>
          <Select
            labelId="mic-list"
            id="microphone-select"
            label="Microphone Devices"
            onChange={(e) => handleSwitchMicrophone(e.target.value)}
            value={activeMic || ""}
            sx={{
              color: get_root_value("--input-text-color"),
            }}
          >
            {micList.map((mic) => (
              <MenuItem key={mic.deviceId} value={mic.deviceId}>
                {mic.label || "Unnamed Microphone"}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

export default LiveStreamSettings;
