import React, { useEffect, useState, useRef } from "react";

export default function HiddenAudioPlayer({ fileUrl }) {
  const [isMuted, setIsMuted] = useState(true);
  const audioRef = useRef(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.muted = isMuted;
    }
  }, [isMuted]);

  useEffect(() => {
    const tryPlayAudio = () => {
      if (audioRef.current) {
        audioRef.current.play().catch((error) => {
          console.log("Autoplay attempt failed:", error);
        });
      }
    };

    tryPlayAudio();

    const handleUserClick = () => {
      if (audioRef.current) {
        audioRef.current.muted = false;
        tryPlayAudio();
      }
      window.removeEventListener("click", handleUserClick);
    };

    window.addEventListener("click", handleUserClick);

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      window.removeEventListener("click", handleUserClick);
    };
  }, [fileUrl]);

  const toggleMute = () => {
    setIsMuted((prevMute) => !prevMute);
  };

  return (
    <div className="w-100 hidden-audio-player">
      <audio ref={audioRef} src={fileUrl} loop muted={isMuted} />
      <button onClick={toggleMute}>{isMuted ? "Unmute" : "Mute"} Audio</button>
    </div>
  );
}
