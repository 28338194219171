import { invokeApi } from "../../bl_libs/invokeApi";

export const live_stream_by_module_id_api = async (module_id) => {
  const requestObj = {
    path: `api/live_stream/get/${module_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const live_meeting_by_meeting_id_api = async (meeting_id) => {
  const requestObj = {
    path: `api/meeting/meeting_detail/${meeting_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const record_media_stream_api = async (data) => {
  const requestObj = {
    path: `api/stream`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_guest_user_to_meeting = async (meeting_id, data) => {
  const requestObj = {
    path: `api/live_stream/join_meeting/${meeting_id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
