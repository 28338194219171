import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ScalingAnswerMission from "./ScalingAnswerMission";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import AnalyticsChart from "./AnalyticsChart";
import PieMissionChart from "./PieMissionChart";
import { get_short_string } from "src/utils/constants";
import ReactApexChartDashboard from "./ReactApexChartDashboard";
import GroupedChart from "./GroupedChart";

const MissionReport = ({ reportData, graphData, mcqData, graphStructure }) => {
  const [expanded, setExpanded] = useState(0); // State to track which accordion is open

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <div className="container">
        {(graphStructure.length > 0 || mcqData.length > 0) && (
          <h4
            className="mt-3 mb-0"
            style={{ color: "#ffd889", fontSize: "20px" }}
          >
            Mission Report Graph Overview
          </h4>
        )}
        <div className="row">
          {graphStructure.length > 0 && (
            <div className="col-lg-12 mt-3">
              <div className="card p-2">
                <GroupedChart graphStructure={graphStructure} />
              </div>
            </div>
          )}
          {/* {graphData.length > 0 &&
            graphData.map((item, index) => {
              return (
                <>
                  <div
                    className={`${
                      index === graphData.length - 1 &&
                      graphData.length % 2 !== 0
                        ? "col-lg-12"
                        : graphData.length > 1
                        ? "col-lg-6"
                        : "col-lg-12"
                    } mt-2 h-100 ${
                      index === graphData.length - 1 &&
                      graphData.length % 2 !== 0
                        ? "pe-2"
                        : index % 2 === 0
                        ? "pe-0"
                        : "pe-2"
                    }`}
                  >
                    <div className="card h-100 pt-3">
                      <Tooltip title={item.question_statement}>
                        <div className="d-flex align-items-baseline ms-2 me-2">
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#ffd889",
                            }}
                          >
                            Q.
                          </span>
                          <h4
                            class="ps-1"
                            style={{ fontSize: "14px", fontWeight: "200" }}
                          >
                            {get_short_string(item.question_statement, 50)}
                          </h4>
                        </div>
                      </Tooltip>
                      <AnalyticsChart item={item} />
                    </div>
                  </div>
                </>
              );
            })} */}
          {mcqData.length > 0 &&
            mcqData.map((item, index) => {
              return (
                <>
                  {" "}
                  <div
                    className={`${
                      index === mcqData.length - 1 && mcqData.length % 2 !== 0
                        ? "col-lg-12"
                        : mcqData.length > 1
                        ? "col-lg-6"
                        : "col-lg-12"
                    } mt-2 ${
                      index === mcqData.length - 1 && mcqData.length % 2 !== 0
                        ? "pe-2"
                        : index % 2 === 0
                        ? "pe-0"
                        : "pe-2"
                    }`}
                  >
                    <div className="card h-100 pt-3">
                      <Tooltip title={item.question_statement}>
                        <div className="d-flex align-items-baseline ms-2 me-2">
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#ffd889",
                            }}
                          >
                            Q .
                          </span>
                          <h4
                            class="ps-1"
                            style={{ fontSize: "14px", fontWeight: "200" }}
                          >
                            {get_short_string(item.question_statement, 50)}
                          </h4>
                        </div>
                      </Tooltip>
                      <PieMissionChart item={item} />
                    </div>
                  </div>
                </>
              );
            })}

          {reportData.length > 0 && (
            <h4
              className="mt-3 mb-0"
              style={{ color: "#ffd889", fontSize: "20px" }}
            >
              Mission Report Detail Overview
            </h4>
          )}

          {reportData.length > 0 ? (
            reportData.map((item, index) => {
              return (
                <div className="col-12 mt-2" key={index}>
                  <Accordion
                    expanded={expanded === index}
                    onChange={handleChange(index)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                      className="svg-color"
                    >
                      <div className="d-flex justify-content-between w-100">
                        <Typography
                          className="theme-color"
                          style={{
                            fontSize: "17px",
                            fontWeight: "500",
                            color: "#ffd889",
                          }}
                        >
                          {item.title}
                        </Typography>
                        <span
                          style={{
                            color: "#c8c8c8",
                            marginRight: "10px",
                          }}
                        >
                          {/* {reply.date} */}
                        </span>
                      </div>
                    </AccordionSummary>
                    {item?.questions?.questions?.map((reply, replyIndex) => {
                      let find_answer = item?.questions?.question_replies.find(
                        (answer) => answer._id == reply._id
                      );
                      return (
                        <div
                          className="px-3 pb-2 pt-2 card mb-2 ms-2 me-2"
                          style={{ background: "rgb(35 35 35)" }}
                          key={replyIndex}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <Typography className="question-text">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: reply.question_statement,
                                }}
                              />
                            </Typography>
                          </div>
                          {/* <div className="mb-3 mt-2">
                          {reply.answer_statement}
                        </div> */}
                          {reply?.question_type === "mcq" && (
                            <div className="mt-2">
                              <FormControl style={{ width: "100%" }}>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue=""
                                  name="radio-buttons-group"
                                  value={find_answer?.answer_statement}
                                >
                                  {reply?.options.map((option, optionIndex) => (
                                    <div
                                      className={`question-answer  ${
                                        find_answer?.answer_statement
                                          ? option ===
                                            find_answer?.answer_statement
                                            ? "selectedAnswer"
                                            : ""
                                          : ""
                                      }`}
                                      key={option.id}
                                    >
                                      <FormControlLabel
                                        value={option}
                                        id={`capsule${optionIndex}`}
                                        className="answer"
                                        control={<Radio />}
                                        label={option}
                                      />
                                    </div>
                                  ))}
                                </RadioGroup>
                              </FormControl>
                            </div>
                          )}

                          {reply.question_type === "scaling" && (
                            <div className="mt-2">
                              <FormControl>
                                <ul className="list-unstyled pt-1 mb-0">
                                  <li className="row">
                                    <div className="col-12">
                                      <ScalingAnswerMission
                                        question={reply}
                                        find_answer={find_answer}
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </FormControl>
                            </div>
                          )}
                          {reply.question_type === "checkbox" && (
                            <div className="mt-2">
                              <FormControl style={{ width: "100%" }}>
                                <FormGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue=""
                                  name="radio-buttons-group"
                                  value={reply?.answer}
                                >
                                  {reply?.options.map((option, index) => {
                                    let checkbox_answer =
                                      find_answer?.answer_statement?.find(
                                        (old_answer) => old_answer == option
                                      );
                                    return (
                                      <div
                                        className={`question-answer`}
                                        key={option.id}
                                      >
                                        <FormControlLabel
                                          value={option}
                                          id={`capsule${index}`}
                                          className="answer"
                                          control={
                                            <Checkbox
                                              checked={
                                                checkbox_answer ? true : false
                                              }
                                            />
                                          }
                                          label={option}
                                        />
                                      </div>
                                    );
                                  })}
                                </FormGroup>
                              </FormControl>
                            </div>
                          )}

                          {reply.question_type === "textarea" && (
                            <div className="mt-2">
                              <FormControl style={{ width: "100%" }}>
                                <TextField
                                  id="outlined-multiline-static"
                                  multiline
                                  rows={4}
                                  className="mb-3"
                                  value={find_answer?.answer_statement}
                                  variant="outlined"
                                  disabled
                                />
                              </FormControl>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </Accordion>
                </div>
              );
            })
          ) : (
            <div className="mt-4">
              <RecordNotFound title="Report Not Found" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MissionReport;
